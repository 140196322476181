<template>
    <div class="game-results">
        <transition>
            <div class="game-results__container">
                <div class="game-results__card-border-dashed">
                    <div class="game-results__card-border">
                        <div class="game-results__card">
                            <div class="game-results__body">
                                <div class="game-results__title">{{ title }}</div>
                                <div class="stars">
                                    <GameResultsStar
                                        v-for="star in stars"
                                        :key="`star-${star.index}`"
                                        :achieved="star.achieved"
                                        :animationTimeout="star.animationTimeout"
                                        :index="star.index"
                                    />
                                </div>
                                <div class="game-results__footer">
                                    <button class="button--primary" @click="$emit('again')">Пройти ещё раз</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CharacterCard :characterPhrase="phrase.text" :characterName="phrase.character">
                    <button @click="$emit('next')" class="button--primary">Далее</button>
                </CharacterCard>
            </div>
        </transition>
    </div>
</template>
<script>
import GameResultsStar from './GameResultsStar.vue';
import CharacterCard from './cards/CharacterCard.vue';
/**
 * @category GameResults
 * @module GameResults
 *
 * @desc Главный компонент экрана результатов
 * @vue-prop {String} title - Заголовок экрана
 * @vue-prop {String} starsCount - Кол-во звёзд
 * @vue-prop {String} phrase - Объект фразы персонажа
 *
 * @vue-computed {Array} stars - Возвращает массив со звёздами для отрисовки
 *
 */
export default {
    name: 'GameResults',
    components: { GameResultsStar, CharacterCard },
    /**
     * title - Заголовок экрана
     * starsCount - кол-во звезд для отображения
     * phrase - объект фразы персонажа
     */
    props: {
        title: { type: String, required: true },
        starsCount: { type: Number, required: false, default: 2 },
        phrase: { type: Object, required: false, default: () => ({ text: 'Фраза персонажа' }) },
    },

    computed: {
        stars() {
            return Array.apply(null, { length: 3 }).map((el, index) => {
                return {
                    achieved: index > this.starsCount - 1 ? false : true,
                    index,
                    animationTimeout: index * 500 + 500,
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$game-bg-gradient: linear-gradient(180deg, #c1eefb 0%, #e6f8fe 61.46%, #a6d9ef 69.79%, #c7eaf9 82.29%, #f0fbff 100%);
//Stroke gradiets
$gradient-stroke-violet-dark: linear-gradient(180deg, #6d8dff 0%, #456eff 65.1%, #6d8dff 100%);

.v-enter-active,
.v-leave-active {
    transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.game-results {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-size: 100% 100% !important;
    background-position: top;
    transition: all 2s ease-in;

    &__container {
        position: relative;
        margin: 0 auto;
        aspect-ratio: 16/9;
        padding-top: 1%;
        box-sizing: border-box;
        max-height: 100%;
        display: flex;
        padding-bottom: 0;
        width: 99%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__card {
        background: $gradient-stroke-violet-dark;
        padding: 0.4em;
        border-radius: 1.6em;
        @media (max-width: 900px) {
            border-radius: 0.8em;
            padding: 0.25em;
        }
        &-border-dashed {
            border: 0.1em dashed #fff;
            padding: 1em;
            border-radius: 1.6em;
            margin-bottom: 5em;
            @media (max-width: 900px) {
                border: 0.02em dashed #fff;
                margin-top: 2em;
                margin-bottom: 2em;
                border-radius: 1em;
                padding: 0.3em;
            }
        }
        &-border {
            padding: 1em;
            border-radius: 1.6em;
            border: 0.15em solid #c0ceff;
            @media (max-width: 900px) {
                border-radius: 1em;
                padding: 0.3em;
                border: 0.1em solid #c0ceff;
            }
        }
    }
    &__body {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2em;
        padding-left: 2em;
        padding-right: 2em;
        background: $gradient-stroke-violet-dark;
        border-radius: 1.4em;
        background: linear-gradient(180deg, #9eb3ff 2.68%, #809cff 7.37%, #9eb3ff 92.76%, #bdcaff 97.45%);
        box-shadow: 0px 0.15em 0.25em rgba(75, 115, 255, 0.4), inset 0px 0.05em 0.1em rgba(116, 128, 185, 0.2);
        @media (max-width: 900px) {
            padding-left: 2em;
            padding-right: 2em;
            padding: 1em;
            border-radius: 0.5em;
        }
    }
    &__footer {
        flex: 1 100%;
        display: flex;
        justify-content: center;
        padding-top: 3em;
        padding-bottom: 2em;
        @media (max-width: 900px) {
            padding-top: 1em;
            padding-bottom: 0.2em;
        }
        z-index: 9999;
    }

    &__title {
        background: linear-gradient(180deg, #6d8dff 0%, #456eff 65.1%, #6d8dff 100%);
        position: absolute;
        top: -2.3em;
        padding: 0.3em;
        padding-left: 3em;
        padding-right: 3em;
        border-radius: 0.5em 0.5em 0 0;
        font-size: 2em;
        font-weight: 800;
        color: #ffffff;

        /* text/navy */

        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
        @media (max-width: 900px) {
            padding-left: 1.5em;
            padding-right: 1.5em;
            font-size: 0.9em;
            border-radius: 0.3em 0.3em 0 0;
        }
    }
}

.stars {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 1em;
    height: 80%;
    z-index: 1;
}

.next-button {
    background: #5172ed;
    box-shadow: 0px 0.3em 0px #3a3eaf;
    padding: 0.6em 2.6em;
    border-radius: 3em;
    border: none;
    color: #fff;
    text-shadow: 0px 0.1em 0px rgba(0, 0, 0, 0.3);
    margin-top: -0.3em;
    .button-text {
        font-size: 1.5em;
        font-weight: 800;
    }
}
</style>
