export default {
    namespaced: true,

    state: {
        isShowDevelopMenu: true,
    },

    mutations: {
        setDevelopMenuShowing(state, payload) {
            state.isShowDevelopMenu = payload;
        },
    },

    actions: {
        toggleDevelopMenuShowing({ commit, state }, payload) {
            let value;
            if (typeof payload === 'boolean') {
                value = payload;
            } else {
                value = !state.isShowDevelopMenu;
            }
            commit('setDevelopMenuShowing', value);
        },
    },

    getters: {
        isShowDevelopMenu: ({ isShowDevelopMenu }) => {
            if (process.env?.VUE_APP_DEVELOP_MENU !== 'show') return false;
            return isShowDevelopMenu;
        },
    },
};
