<template>
    <div class="developAbsoluteMenu" :style="developAbsoluteMenuStyle">
        <b-button-group>
            <template v-if="minifyMenu">
                <b-button v-b-toggle.developAbsoluteMenuContent>
                    <b-icon-dash-lg></b-icon-dash-lg>
                </b-button>
            </template>
        </b-button-group>
        <b-collapse id="developAbsoluteMenuContent">
            Сценарии:
            <br />
            <b-button-group>
                <b-button @click="setDifficulty('elementary')">1-4 </b-button>
                <b-button @click="setDifficulty('middle')">5-9 </b-button>
                <b-button @click="setDifficulty('high')"> 10-1 </b-button>
            </b-button-group>
            <br />
            Навигация по сценарию:
            <br />
            <b-button-group>
                <b-button @click="previousScreen">prev</b-button>
                <b-button @click="nextScreen">next</b-button>
            </b-button-group>
            <div>
                <ScriptView />
            </div>
        </b-collapse>
    </div>
</template>

<script>
import ScriptView from '@/components/DevelopAbsoluteMenu/ScriptView.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DevelopAbsoluteMenu',
    components: { ScriptView },
    data: () => ({
        minifyMenu: true,
        showDevelopAbsoluteMenu: true,
        position: 'up_right', // "up_left", "up_right", "down_right"
    }),
    computed: {
        ...mapGetters('ScreensStore', ['getScript', 'getCurrentScren']),
        developAbsoluteMenuStyle() {
            let style = {};
            switch (this.position) {
                case 'down_left':
                    style = {
                        alignItems: 'start',
                        bottom: 0,
                        left: 0,
                    };
                    break;
                case 'down_right':
                    style = {
                        alignItems: 'end',
                        bottom: 0,
                        right: 0,
                    };
                    break;
                case 'up_left':
                    style = {
                        alignItems: 'start',
                        top: 0,
                        left: 0,
                    };
                    break;
                case 'up_right':
                    style = {
                        alignItems: 'end',
                        top: 0,
                        right: 0,
                    };
                    break;
            }
            return style;
        },
    },
    methods: {
        ...mapActions('development', ['toggleDevelopMenuShowing']),
        ...mapActions('ScreensStore', ['nextScreen', 'previousScreen']),
        ...mapActions('GameStore', ['setDifficulty']),
        setPosition(value) {
            this.position = value;
        },
    },
};
</script>

<style scoped>
#developAbsoluteMenuContent {
    padding: 20px;
    background-color: white;
}

.developAbsoluteMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 9000;
}
</style>
