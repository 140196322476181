<template>
    <div class="select-lookup">
        <button type="button" class="toggle-select" @click="toggleList">
            {{ selected ? selected.name : 'Выбрать' }}
        </button>
        <div v-show="listOpen" class="list">
            <input class="filter" v-model="filter" type="text" />
            <ul>
                <li :class="{ selected: selected === null }" @click="selectItem(null)">Выбрать</li>
                <li
                    :class="{ selected: selected === item }"
                    v-for="item in filteredList"
                    :key="item.id"
                    @click="selectItem(item)"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped lang="scss">
button {
    --padding: 0;
    --button-margin-bottom: 0;
    padding: 0;
    border-radius: 0;
    text-shadow: none;
    margin-top: 0;
    transform: 0;
    box-shadow: 0;
}

button {
    --shadow-color: transparent;
    background: transparent;
    color: white;
}
.select-lookup {
    position: relative;
}

.toggle-select {
    width: 100%;
    padding: 0.625rem 1.375em;

    font-size: 1em;
    line-height: 170%;
    text-align: left;
    color: #a7a8c1;
    font-weight: normal;

    background-color: transparent;
    border: 1px solid rgba(167, 168, 193, 0.3);
    border-radius: 3em;

    background-image: url('~@/assets/select-arrow.svg');
    background-size: auto 0.5625em;
    background-position: center right 1.5em;
    background-repeat: no-repeat;

    cursor: pointer;
    &:hover {
        background-color: transparent;
        background-image: url('~@/assets/select-arrow.svg');
        background-size: auto 0.5625em;
        background-position: center right 1.5em;
        background-repeat: no-repeat;
    }
    &:active:not([disabled]) {
        background-color: transparent;
        background-image: url('~@/assets/select-arrow.svg');
        background-size: auto 0.5625em;
        background-position: center right 1.5em;
        background-repeat: no-repeat;
    }
}

.list {
    position: absolute;
    left: 0;
    right: 0;
    top: 3em;
    z-index: 10;

    background-color: #fff;
    border: 1px solid #aaa;

    input {
        width: calc(100% - 2px);
        border: 1px solid #aaa;
        margin: 1px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;

        max-height: 12.25em;
        overflow-y: auto;

        li {
            padding: 0.375em;
            line-height: 170%;

            &.selected {
                background-color: #ddd;
            }

            &:hover {
                background-color: #5897fb;
            }
        }
    }
}
</style>

<script>
export default {
    props: {
        list: Array,
        value: [String, Number],
    },
    data: () => ({
        listOpen: false,
        selected: null,
        filter: '',
    }),
    computed: {
        filteredList() {
            if (this.filter) {
                const match = new RegExp(this.filter, 'i');
                return this.list.filter(item => item.name.match(match));
            } else {
                return this.list;
            }
        },
    },
    methods: {
        selectItem(item) {
            this.selected = item;
            this.closeList();
            this.$emit('input', (item && item.id) || null);
        },
        toggleList() {
            if (this.listOpen) {
                this.closeList();
            } else {
                this.openList();
            }
        },
        closeList() {
            this.listOpen = false;
        },
        openList() {
            this.listOpen = true;
            this.filter = '';
        },
        _clickOutside(ev) {
            if (!this.$el.contains(ev.target)) {
                this.closeList();
            }
        },
    },
    created() {
        this._boundClickOutside = this._clickOutside.bind(this);
        window.addEventListener('click', this._boundClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener('click', this._boundClickOutside);
    },
};
</script>
