export async function loadImages(images, { imageLoadType = 'fetch', imageSrcType = 'base64' } = {}) {
    if (!Array.isArray(images) || images.length === 0) return [];
    switch (imageLoadType) {
        case 'cache':
            return cacheLoadImages(images, imageSrcType);
        case 'fetch':
        default:
            return fetchLoadImages(images, imageSrcType);
    }
}

async function fetchLoadImages(images, imageSrcType) {
    return Promise.all(images.map(image => fetchLoadImage(image, imageSrcType)));
}

async function fetchLoadImage(image, imageSrcType) {
    try {
        const fetchResult = await fetch(image.src);
        const blob = await fetchResult.blob();
        let srcData = await blobToScrData(blob, imageSrcType, image);
        return { srcData, ...image };
    } catch (error) {
        return { error, ...image };
    }
}

async function cacheLoadImages(images, imageSrcType) {
    return Promise.all(images.map(image => cacheLoadImage(image, imageSrcType)));
}

async function cacheLoadImage(image, imageSrcType) {
    const srcData = await cacheLoad(image, imageSrcType);
    return { srcData, ...image };
}

async function cacheLoad(image, imageSrcType) {
    return new Promise(resolve => {
        let img = new Image();
        img.onload = img.onerror = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            switch (imageSrcType) {
                case 'base64':
                    resolve(canvas.toDataURL('image/png', 1.0));
                    break;
                case 'blob':
                    canvas.toBlob(blob => resolve(URL.createObjectURL(blob)), 'image/png', 1.0);
                    break;
                case 'baseSrc':
                default:
                    resolve(img.src);
                    break;
            }
        };
        img.crossOrigin = 'Anonymous';
        img.src = image.src;
    });
}

async function blobToScrData(blob, imageSrcType, image) {
    switch (imageSrcType) {
        case 'blob':
            return new Promise(resolve => resolve(URL.createObjectURL(blob)));
        case 'base64':
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        case 'baseSrc':
        default:
            return new Promise(resolve => resolve(image.src));
    }
}

export function imagesPainingStatus(images) {
    images = Array.from(images);
    return Promise.all(
        images.map(
            image =>
                new Promise(resolve => {
                    image.onload = image.onerror = resolve;
                })
        )
    );
}
