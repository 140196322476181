<template>
    <div class="frame-wrapper" v-observe-visibility="visibilityChanged" :style="{ ...frame.style }">
        <transition name="frame">
            <div class="comics-frame" v-if="frame.show">
                <img class="comics-frame__bg" :src="backgroundSrc" alt="" />
                <transition name="bubbles" appear>
                    <div class="comics-frame__bubbles">
                        <img :src="bubblesSrc" alt="" />
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>
<script>
/**
 * @category ComicsScreen
 * @module ComicsScreen/ComicsFrame
 *
 * @desc Компонент кадра комикса
 * @vue-prop {String} backgroundSrc - Фоновая картинка фрейма
 * @vue-prop {String} bubblesSrc - Бабблы фрейма
 * @vue-prop {String} frame - Объект фрейма
 *
 */
export default {
    name: 'ComicsFrame',
    props: {
        backgroundSrc: { required: true, type: String },
        bubblesSrc: { required: false, type: String },
        frame: { required: true, type: Object },
    },

    methods: {
        /**
         * Вызывает ивент о том, что кадр виден
         * @param {boolean} isInViewport
         */
        visibilityChanged(isInViewport) {
            this.$emit('animate', { ...this.frame, isInViewport });
        },
    },
};
</script>

<style scoped lang="scss">
.frame-wrapper {
    width: 100%;
    height: 100%;
}
.comics-frame {
    border: 1em solid #fff;
    @media (max-width: 900px) {
        border: 0.5em solid #fff;
    }
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.2);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: opacity 1.5s;
    width: 100%;
    height: 100%;
    &__bubbles {
        width: 100%;
        height: 100%;
        position: absolute;
        img {
            width: 100%;
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1;
}

.frame-enter-active {
    transition: opacity 0.8s;
}
.frame-leave-active {
    opacity: 0;
}
.frame-enter,
.frame-leave-to {
    opacity: 0;
}

.bubbles-enter-active {
    transition: opacity 2s;
}
.bubbles-leave-active {
    opacity: 0;
}
.bubbles-enter,
.bubbles-leave-to {
    opacity: 0;
}
</style>
