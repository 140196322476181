<template>
    <div class="progress-screen-container">
        <div
            :class="[
                'stages-container',
                {
                    'even-stages': stages.length % 2 === 0,
                    'odd-stages': stages.length % 2 !== 0,
                },
            ]"
        >
            <div class="progress-container">
                <div class="progress-line" :style="computeProgressWidth"></div>
            </div>
            <template v-for="(item, index) in stages">
                <stage-card
                    :key="index"
                    :status="computeStatus(index + 1)"
                    :title="item.name"
                    :role="item.role"
                    :stageImage="item.stagePic"
                    :cardIndex="index + 1"
                    :showMarker="
                        (index === currentStage - 1 && animationStep < 4) ||
                        (index === currentStage && animationStep === 4)
                    "
                />
                <div class="divider-container" :key="item.index"><div class="divider"></div></div>
            </template>
        </div>
        <div class="character-container">
            <character-card :characterPhrase="screenConfig.characterPhrase" :characterName="screenConfig.characterName">
                <button @click="$emit('next')" class="button--primary">Далее</button>
            </character-card>
        </div>
    </div>
</template>

<script>
/**
 * @category ProgressScreen
 * @module ProgressScreen
 * @desc Компонент экрана прогресса. Показывается после каждого выполненного этапа и отображает текущий прогресс.
 *
 * @vue-data {Object | null} screenConfig Переменная для хранения конфига экрана прогресса
 * @vue-data {array | null} stages Переменная для хранения массива списка этапов
 * @vue-data {number | null} currentStage Переменная для хранения индекса текущего этапа
 * @vue-data {number} animationStep Переменная для хранения текущего этапа анимации экрана прогресса
 *
 * @vue-computed {string} getDifficulty возвращает сложность игры (для 1-4, 5-9, 10-11 классов) из Vuex
 * @vue-computed getImage возвращает изображение загруженное в Vuex
 * @vue-computed {array} imageSrcListFromConfig возвращает массив картинок аватаров персонажей
 * @vue-computed {string} computeProgressWidth возвращает css - свойство ширины прогресс-бара
 */
import { mapActions, mapGetters } from 'vuex';
import screenMixin from '@/mixins/screenMixin';
import StageCard from '@/components/cards/StageCard.vue';
import CharacterCard from '@/components/cards/CharacterCard.vue';

export default {
    name: 'ProgressScreen',
    components: { StageCard, CharacterCard },
    mixins: [screenMixin],
    data: () => ({
        screenConfig: null,
        stages: null,
        currentStage: null,
        animationStep: 0,
    }),
    computed: {
        ...mapGetters('GameStore', ['getDifficulty']),
        ...mapGetters('assets', ['getImage']),
        imageSrcListFromConfig() {
            const imageSrcList = [];
            const { content } = this.screenConfig;
            if (!content) return imageSrcList;
            for (let i = 1; i <= content.stages.length; i++) {
                imageSrcList.push(`${require(`@/assets/progress/${this.getDifficulty}/stage${i}.svg`)}`);
            }
            return imageSrcList;
        },
        computeProgressWidth() {
            const gap = this.$device !== 'mobile' ? 3.8 : 1.3;
            const step = this.$device !== 'mobile' ? 11.25 : 3.875;
            if (this.animationStep === 0 && this.currentStage === 1) {
                return { width: '0%' };
            }
            if (this.animationStep >= 3) {
                if (this.currentStage === this.stages.length) {
                    return {
                        width: '100%',
                    };
                } else
                    return {
                        width: `${2 * step + this.currentStage * step - gap}em`,
                    };
            }
            return {
                width: `${step + this.currentStage * step - gap}em`,
            };
        },
    },
    methods: {
        ...mapActions('ScreensStore', ['nextScreen']),
        /**
         * Функция вычисления статуса карточки этапа в зависимости от этапа анимации
         * @param {number} num Порядковый номер этапа
         * @returns {string} Возвращает название статуса для карточки
         */
        computeStatus(num) {
            if (num === this.currentStage && (this.animationStep === 0 || this.animationStep === 1)) {
                return 'unlocked';
            }
            if (num === this.currentStage && this.animationStep >= 2) {
                return 'completed';
            }
            if (num === this.currentStage + 1 && this.animationStep >= 4) {
                return 'unlocked';
            }
            if (num < this.currentStage) {
                return 'completed';
            }
            return 'not-started';
        },
        /**
         * Функция инициализации конфига экрана прогресса при его открытии
         */
        loadConfig() {
            this.screenConfig = require(`@/configs/${this.id}/${this.getDifficulty}.json`);
            this.stages = this.screenConfig?.content?.stages;
            this.currentStage = this.screenConfig?.currentStage;
        },
    },
    /**
     * В хуке mounted происходит запуск и переключение этапов анимации прогресс бара
     */
    mounted() {
        setTimeout(() => {
            this.currentStage == 1 ? (this.animationStep = 1) : (this.animationStep = 2);
            let animationInterval = setInterval(() => {
                if (this.animationStep === 4) {
                    clearInterval(animationInterval);
                    return;
                }
                this.animationStep++;
            }, 1000);
        }, 300);
    },
};
</script>
<style lang="scss">
@use 'sass:math';

.progress-screen-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
//FOR 6 STAGES
.even-stages {
    grid-template-columns: repeat(9, 11.25em);
    .stage-card-container {
        @for $i from 1 through 3 {
            &:nth-child(#{4 * $i - 2}) {
                $start: #{$i * 2};
                $end: #{2 + $i * 2};
                grid-column: $start/$end;
                grid-row: 1;
            }
        }
        @for $i from 1 through 3 {
            &:nth-child(#{4 * $i}) {
                $start: #{1 + $i * 2};
                $end: #{3 + $i * 2};
                grid-column: $start/$end;
                grid-row: 3;
            }
        }
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(9, 3.875em);
    }
}
//FOR 7 STAGES
.odd-stages {
    grid-template-columns: repeat(10, 11.25em);
    .stage-card-container {
        @for $i from 1 through 4 {
            &:nth-child(#{4 * $i - 2}) {
                $start: #{$i * 2};
                $end: #{2 + $i * 2};
                grid-column: $start/$end;
                grid-row: 1;
            }
        }
        @for $i from 1 through 3 {
            &:nth-child(#{4 * $i}) {
                $start: #{1 + $i * 2};
                $end: #{3 + $i * 2};
                grid-column: $start/$end;
                grid-row: 3;
            }
        }
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(10, 3.875em);
    }
}
.stages-container {
    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 23em 2.75em 23em;
    height: 85%;
    width: 100%;
    padding-top: 1em;
    .stage-card-container {
        margin: 0 auto;
    }
    @media (max-width: 900px) {
        grid-template-rows: 7.875em 0.9375em 7.875em;
    }
}
.character-container {
    display: flex;
    align-items: center;
    height: 15%;
    width: 100%;
    .button--primary {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (max-width: 900px) {
        .button--round {
            margin-right: 1.375em;
        }
    }
}
.progress-container {
    grid-row: 2;
    grid-column: 1/11;
    height: 2.125em;
    margin: 0 3.625em;
    margin-top: 0.3125em;
    overflow: hidden;
    background: linear-gradient(180deg, #9bb1ff 0%, #8ba5ff 65.1%, #9bb1ff 100%);
    border: 0.3125em solid rgba(81, 114, 237, 1);
    border-radius: 1.25em;
    .progress-line {
        background: linear-gradient(180deg, #5ffbaf 2.68%, #10d676 79.68%, #1ff48d 97.45%);
        flex-direction: column;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        height: 100%;
        transition: width 1s;
    }
    @media (max-width: 900px) {
        height: 0.729em;
        margin: 0 1.2em;
        margin-top: 0.1063em;
        border: 0.1063em solid rgba(81, 114, 237, 1);
        border-radius: 0.4288em;
    }
}
.divider-container {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;

    @for $i from 1 through 7 {
        &:nth-child(#{2 * $i + 1}) {
            $start: #{$i + 1};
            $end: #{$i + 3};
            grid-column: $start/$end;
        }
    }
    &:nth-child(4n-1) {
        align-items: flex-end;
        margin-bottom: -2.3em;
        grid-row: 1;
        @media (max-width: 900px) {
            margin-bottom: -0.767em;
        }
    }
    &:nth-child(4n + 1) {
        align-items: flex-start;
        margin-top: -2.3em;
        grid-row: 3;
        @media (max-width: 900px) {
            margin-top: -0.767em;
        }
    }
    .divider {
        background: rgba(81, 114, 237, 1);
        height: 5em;
        width: 0.5em;
        @media (max-width: 900px) {
            height: 2em;
            width: 0.1713em;
        }
    }
}
</style>
