export default {
    namespaced: true,

    state: {
        isShow: false,
        message: 'Идет загрузка, ожидайте',
    },

    mutations: {
        SET_LOADER(state, { isShow, message }) {
            state.isShow = isShow;
            state.message = message;
        },
    },

    actions: {
        setLoader({ commit }, { isShow, message }) {
            commit('SET_LOADER', { isShow, message });
        },
    },

    getters: {
        isShowLoader: ({ isShow }) => isShow,
        loaderMessage: ({ message }) => message,
    },
};
