var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stage-card-container"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showMarker)?_c('div',{class:[
                'finish-marker',
                {
                    'even-stage': _vm.cardIndex % 2 === 0,
                    'odd-stage': _vm.cardIndex % 2 !== 0,
                },
                _vm.status,
            ]},[_c('progress-marker')],1):_vm._e()]),_c('div',{class:['color-border-outer', _vm.status]},[_c('div',{class:['color-border-inner', _vm.status]},[_c('div',{staticClass:"card",class:_vm.status},[_c('div',{staticClass:"card-index"},[_c('div',{staticClass:"outer-border"},[_c('div',{staticClass:"inner-border"},[_vm._v(_vm._s(_vm.cardIndex))])])]),_c('div',{staticClass:"icon-container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.status !== 'completed')?_c(_vm.computedIcon,{tag:"component"}):_c('img',{attrs:{"src":_vm.getCharacterImage}})],1)],1),_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"role-container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.status === 'completed')?_c('div',{key:1},[_c('div',{staticClass:"title"},[_vm._v("Открыта роль")]),_c('div',{staticClass:"role"},[_vm._v(_vm._s(_vm.role))])]):_c('div',{key:2,staticClass:"role-icon"},[_c('locked-marker-small')],1)])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }