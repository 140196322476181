<template>
    <div class="loader" v-if="isShowLoader">
        {{ loaderMessage }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppLoader',
    computed: {
        ...mapGetters('loader', ['isShowLoader', 'loaderMessage']),
    },
};
</script>

<style scoped>
.loader {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000;
    mix-blend-mode: darken;
    color: white;
    z-index: 8000;
    animation: blink 1.5s linear 0s infinite;
    transition: all 0.3s;
    animation-delay: 0.5s;
    opacity: 0;
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}
</style>
