<template>
    <div class="portrait-container">
        <div class="message-container">
            <div class="image-container">
                <img :src="require('@/assets/portraitModeCharacter.svg')" alt="" />
            </div>
            <div class="message">
                <div class="message-text" v-if="deviceType === 'mobile-portrait'">Переверни экран горизонтально!</div>
                <div class="message-text" v-else>Мы советуем развернуть экран для полного погружения в игру!</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PortraitNotification',
    props: {
        deviceType: {
            type: String,
            default: 'mobile-portrait',
        },
    },
};
</script>
<style lang="scss" scoped>
.portrait-container {
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.message-container {
    .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .message {
        max-width: 19rem;
        padding: 1rem;
        border-radius: 0.857rem;
        border: 0.171rem solid #9bb1ff;
        background: linear-gradient(180deg, #c1c7dc 0%, #ffffff 10.42%, #ffffff 90.1%, #c1c6d9 100%);
        box-shadow: 0px 1.37089px 5.48357px rgba(129, 174, 186, 0.4);
        .message-text {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 125%;
            text-align: center;
            color: #000000;
        }
    }
}
</style>
