<template>
    <div class="comics-screen" v-if="screenConfig && isImagesLoaded">
        <div class="comics-screen__container">
            <h2 class="comics-heading" v-if="screenConfig.title">{{ screenConfig.title }}</h2>

            <div
                class="comics-screen__grid"
                :style="{ gridTemplateRows: `repeat(${totalRowsCount}, ${gridSquareSize})` }"
            >
                <ComicsFrame
                    v-for="(frame, index) in screenConfig.frames"
                    :key="`frame-${index}`"
                    :backgroundSrc="getImage(imagesMap[index].bg)"
                    :bubblesSrc="getImage(imagesMap[index].bubbles)"
                    :frame="frame"
                    @animate="animateFrame"
                />
            </div>
            <div class="comics-screen__button">
                <transition name="next-button" appear :duration="500">
                    <button class="button" @click="nextScreen" v-if="showNextButton">
                        {{ screenConfig.nextButton.text }}
                    </button>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import screenMixin from '@/mixins/screenMixin';
import { mapGetters, mapActions } from 'vuex';
import ComicsFrame from '@/components/ComicsFrame.vue';
/**
 * @category ComicsScreen
 * @module ComicsScreen
 *
 * @desc Главный компонент комиксов
 * @vue-prop {Object} screenConfig Конфиг игры
 * @vue-prop {boolean} isImagesLoaded Флаг который показывает загрузились ли все ассеты
 *
 * @vue-data {Array} animationQueue - очередь анимации кадров
 * @vue-data {Object | null} animationQueueCheckInterval - интервал-воркер который обрабатывает очередь анимации кадров
 *
 * @vue-computed {Array} imageSrcListFromConfig - Возвращает список картинок для загрузчика
 * @vue-computed {Array} imagesMap - Возвращает карту картинок
 * @vue-computed {Number} totalRowsCount - Возвращает количество строк в комиксе
 * @vue-computed {String} gridSquareSize - Возвращает размер сетки
 * @vue-computed {boolean} showNextButton - Определяет показывать ли кнопку далее или нет
 */
export default {
    name: 'ComicsScreen',
    mixins: [screenMixin],
    data: () => ({
        animationQueue: [],
        animationQueueCheckInterval: null,
    }),
    computed: {
        ...mapGetters('assets', ['getImage']),
        imageSrcListFromConfig() {
            return this.imagesMap
                .map(el => Object.values(el))
                .flat()
                .filter(el => el !== null);
        },
        imagesMap() {
            return this.screenConfig.frames
                ? this.screenConfig.frames.map((frame, index) => {
                      let bubbles = null;
                      try {
                          bubbles = require(`@/assets/comics/${this.id}/${this.$device}/frame-${
                              index + 1
                          }/bubbles.png`);
                      } catch (e) {
                          console.log('has no bubbles');
                      }
                      return {
                          bg: require(`@/assets/comics/${this.id}/${this.$device}/frame-${index + 1}/bg.png`),
                          bubbles: bubbles,
                      };
                  })
                : null;
        },
        totalRowsCount() {
            return this.screenConfig.frames[this.screenConfig.frames.length - 1].yEnd;
        },
        gridSquareSize() {
            return this.$device === 'mobile' ? '4.375em' : '7.5em';
        },

        showNextButton() {
            return this.screenConfig.frames[this.screenConfig.frames.length - 1].show;
        },
    },
    mounted() {
        this.animationQueueCheckInterval = setInterval(() => {
            const lastFrame = this.animationQueue.pop();
            if (lastFrame) this.screenConfig.frames[lastFrame.id].show = true;
            if (this.screenConfig.frames.reduce((acc, el) => acc && el.show, true)) {
                clearInterval(this.animationQueueCheckInterval);
            }
        }, 1000);
    },
    methods: {
        ...mapActions('ScreensStore', ['nextScreen']),
        /**
         * Добавляет кадр в очередь для анимации
         * @param {Object} frame
         */
        animateFrame(frame) {
            if (frame.isInViewport && !frame.show) this.animationQueue.push(frame);
            this.animationQueue.sort((a, b) => b.id - a.id);
        },
        /**
         * Инициализирует конфигурацию экрана
         */
        loadConfig() {
            this.screenConfig = require(`@/configs/${this.id}/${this.$device}.json`);
            this.screenConfig.frames = this.screenConfig.frames.map((frame, index) => ({
                ...frame,
                id: index,
                style: {
                    gridColumn: `${frame.xStart}/${frame.xEnd + 1}`,
                    gridRow: `${frame.yStart}/${frame.yEnd + 1}`,
                },
                show: false,
            }));
        },
    },
    watch: {
        /**
         * Перезагружает конфиг при изменения типа девайса
         */
        $device() {
            this.loadConfig();
        },
    },
    components: { ComicsFrame },
};
</script>
<style scoped lang="scss">
.next-button-enter-active,
.next-button-leave-active {
    transition: opacity 0.3s;
}

.next-button-enter-active {
    transition-delay: 0.3s;
}

.next-button-enter,
.next-button-leave-to {
    opacity: 0;
}
.comics-screen__container {
}
.comics-screen {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &__button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
        padding-bottom: 1em;
        min-height: 10em;
        @media (max-width: 900px) {
            min-height: 3em;
        }
    }
}

.comics-heading {
    font-size: 4em;
    font-weight: 800;
    color: #fff;
    text-shadow: 0px 0.125em 0px rgb(0 0 0 / 30%);
    text-align: center;
    margin-bottom: 0.5em;
    @media (max-width: 900px) {
        font-size: 2em;
    }
}
.comics-screen__grid {
    display: grid;
    grid-template-columns: repeat(12, 7.5em);
    margin: 0 auto;
    justify-content: center;
    grid-gap: 1.5em;
    @media (max-width: 900px) {
        grid-template-columns: repeat(6, 4.375em);
        grid-gap: 0.8em;
    }
}
</style>
