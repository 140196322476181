<template>
    <div class="form-container" ref="form">
        <div class="questionnary" :style="mainContainerStyle">
            <h3 class="step">Шаг {{ Math.floor(step) }}/2</h3>
            <h1 class="title">Настройки прохождения</h1>
            <hr class="divider" />
            <form class="step-form step-1" v-show="step === 1" @submit.prevent="validateFirst()">
                <label class="label required">Вы:</label>
                <div class="radio-group">
                    <input id="type-pupil" type="radio" name="type" v-model="type" value="pupil" />
                    <label for="type-pupil">Ученик</label>
                    <input id="type-teacher" type="radio" name="type" v-model="type" value="teacher" />
                    <label for="type-teacher">Учитель</label>
                    <input id="type-parent" type="radio" name="type" v-model="type" value="parent" />
                    <label for="type-parent">Родитель</label>
                </div>
                <div class="check-group">
                    <input id="not-from-ru" type="checkbox" name="not_from_russia" v-model="notFromRussia" />
                    <label for="not-from-ru">
                        <span class="checkmark"></span>
                        Я не из России
                    </label>
                </div>
                <label v-show="notFromRussia" class="label required">Страна:</label>
                <div v-show="notFromRussia" class="select-group">
                    <select-lookup :list="countries" v-model="countryId" />
                </div>
                <label v-show="!notFromRussia" class="label required">Регион:</label>
                <div v-show="!notFromRussia" class="select-group">
                    <select-lookup :list="regions" v-model="regionId" />
                </div>
                <label v-show="!notFromRussia && regionId" class="label required"
                    >Город (если не нашли свой, выберите центр вашего региона):</label
                >
                <div v-show="!notFromRussia && regionId" class="select-group">
                    <select-lookup :list="cities" v-model="cityId" />
                </div>
                <label v-show="type === 'pupil'" class="label required">Класс:</label>
                <div v-show="type === 'pupil'" class="select-group">
                    <select-lookup :list="years" v-model="yearId" />
                </div>
                <div class="button-group">
                    <button class="submit" type="submit">Дальше</button>
                </div>
            </form>
            <form class="step-form step-2" v-show="step === 2" @submit.prevent="submitOrNext">
                <p v-show="type === 'pupil'" class="label-wide">
                    Ты проходишь тренажёр в одиночку или с одноклассниками?
                </p>
                <p v-show="type === 'teacher'" class="label-wide">
                    Вы проходите тренажер как учитель или даете доступ ученикам?
                </p>
                <p v-show="type === 'parent'" class="label-wide">
                    Вы проходите тренажер как родитель или даете доступ ученикам?
                </p>
                <div class="button-group">
                    <button type="submit" @click="passType = 'self'" class="submit">
                        <span v-show="type === 'pupil'">Я один</span>
                        <span v-show="type === 'teacher'">Учитель</span>
                        <span v-show="type === 'parent'">Родитель</span>
                    </button>
                    <button type="submit" @click="passType = 'several'" class="submit">
                        <span v-show="type === 'pupil'">Нас несколько</span>
                        <span v-show="type === 'teacher'">Ученик</span>
                        <span v-show="type === 'parent'">Ученик</span>
                    </button>
                </div>
                <div class="button-group">
                    <button type="button" @click="step -= 1" class="back">Вернуться назад</button>
                </div>
            </form>
            <form class="step-form step-3" v-show="step === 2.5" @submit.prevent="submit">
                <p class="label-wide">Укажите количество и класс учеников</p>
                <template v-for="(student, i) in students">
                    <label class="label required" :key="`label-${i}`">Класс ученика:</label>
                    <div class="select-group" :key="`select-${i}`">
                        <select-lookup :list="years" v-model="student.year" />
                        <button type="button" v-if="i !== 0" class="delete" @click="deleteStudent(student)"></button>
                    </div>
                </template>
                <div class="button-group">
                    <button type="button" class="add-student" @click="addStudent">Добавить ученика</button>
                </div>
                <div class="button-group">
                    <button type="submit" class="submit">Дальше</button>
                </div>
                <div class="button-group">
                    <button type="button" class="back" @click="step -= 0.5">Вернуться назад</button>
                </div>
            </form>

            <div class="error">
                <span class="error-message" :class="{ animated: showError }" @animationend="showError = false"
                    >Заполните все поля</span
                >
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
button {
    --padding: 0;
    --button-margin-bottom: 0;
    padding: 0;
    border-radius: 0;
    text-shadow: none;
    margin-top: 0;
    transform: 0;
    box-shadow: 0;
}
.form-container {
    width: 100%;
    height: 100%;
    background: white;
    overflow-y: auto;
}
.questionnary {
    margin: 0 auto;
    max-width: 26em;
    min-height: 0;
    padding-top: 5%;
    padding-bottom: 5%;

    cursor: default;

    button {
        padding: 0;
        border: none;
        outline: 0;
        background: 0 0;
        padding: 0;

        cursor: pointer;
    }
}

.step {
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
    color: #a7a8c1;
    letter-spacing: 0.04em;
    line-height: 170%;
    font-size: 1em;
}

.title {
    text-align: center;
    font-size: 1.625em;
    font-weight: 800;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #202020;
}

.divider {
    border-color: #a7a8c1;
    border-bottom: none;
    opacity: 0.3;
}

.step-form > div + label,
.step-form > div + div:not(.button-group) {
    margin-top: 1.25em;
}

.label {
    display: block;

    font-size: 0.875em;
    color: #a7a8c1;
    line-height: 170%;
    letter-spacing: 0.04em;

    margin-bottom: 0.5em;

    &.required:after {
        content: ' *';
        color: red;
    }
}

.radio-group {
    position: relative;

    display: flex;
    justify-content: space-between;

    input[type='radio'] {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    label {
        display: inline-block;
        padding: 0.8rem 1.875em;
        border-radius: 3em;
        border: 1px solid rgba(167, 168, 193, 0.3);

        font-weight: 700;
        font-size: 1em;
        line-height: 140%;
        color: #202020;

        cursor: pointer;
    }

    input:checked + label {
        background-color: #53b7e8;
        color: #fff;
    }
}

.check-group {
    position: relative;

    input[type='checkbox'] {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    label {
        font-size: 0.875em;
        color: #a7a8c1;
        line-height: 170%;
        letter-spacing: 0.04em;

        .checkmark {
            display: inline-block;
            position: relative;
            vertical-align: -0.1em;
            margin-right: 0.8em;

            width: 1em;
            height: 1em;
            background-color: #fff;
            border: 1px solid rgba(167, 167, 167, 0.3);
            border-radius: 0.125em;

            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 0;
                top: -0.125em;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 0.375em;
                height: 0.625em;
                border-style: solid;
                border-color: #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }

    input:checked + label .checkmark {
        background-color: #53b7e8;

        &:after {
            display: block;
        }
    }
}

.select-group {
    position: relative;

    .delete {
        font-size: 1em;
        position: absolute;
        right: -2em;
        top: 0.875em;
        width: 1.25em;
        height: 1.25em;
        background-image: url('~@/assets/plus.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(45deg);
    }
}

.button-group {
    .submit {
        display: inline-block;
        width: 100%;
        padding: 0.8rem 1.875em;
        border-radius: 3em;
        border: 1px solid rgba(167, 168, 193, 0.3);

        font-weight: 700;
        font-size: 1em;
        line-height: 140%;
        color: #fff;

        background-color: #53b7e8;

        margin-top: 2.5em;
    }

    .back {
        font-weight: 700;
        font-size: 0.875em;
        text-align: center;
        color: #53b7e8;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    .add-student {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        text-decoration: none;
        font-weight: 700;
        font-size: 1em;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.04em;
        color: #202020;
        border-bottom: 1px solid #d1ebf8;

        background-image: url('~@/assets/plus.svg');
        background-size: auto 0.6875em;
        background-position: center left;
        background-repeat: no-repeat;
        padding-left: 1.25em;
    }
}

.step-2 .button-group {
    display: flex;
    justify-content: space-around;

    .submit {
        width: auto;
    }
}

.label-wide {
    text-align: center;
    color: #202020;

    line-height: 170%;
    letter-spacing: 0.04em;
}

.step-3 .label-wide {
    margin-bottom: 2.5em;
}

.error {
    position: fixed;
    left: 50%;
    top: 1em;
    transform: translateX(-50%);

    .error-message {
        display: block;
        padding: 0.8rem 1.875em;
        background-color: red;
        color: #fff;
        border-radius: 1.375em;
        font-weight: bold;

        transform: translateY(calc(-100% - 1em));

        animation-duration: 5s;

        &.animated {
            animation-name: show-and-hide;
        }
    }
}

@keyframes show-and-hide {
    0% {
        transform: translateY(calc(-100% - 1rem));
    }
    10% {
        transform: translateY(0);
    }
    90% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-100% - 1rem));
    }
}
</style>

<script>
import { getCountryList, getRegionList, getCityListInRegion, sendQuestionnaire } from '@/api';
import SelectLookup from '@/components/RegisterForm/SelectLookup';

export default {
    inheritAttrs: false,
    components: {
        SelectLookup,
    },
    data: () => ({
        step: 1,
        type: 'pupil',
        notFromRussia: false,
        countries: [],
        countryId: 0,
        regions: [],
        regionId: 0,
        cities: [],
        cityId: 0,
        years: [...Array(11).keys()].map(n => ({ name: `${n + 1}-й`, id: n + 1 })),
        yearId: 0,
        passType: 'self',
        students: [{ year: null }],
        showError: false,
        mainContainerWidth: 0,
        mainContainerHeight: 0,
    }),
    computed: {
        mainContainerStyle() {
            return {
                fontSize:
                    this.$mq == 'sm'
                        ? (16 / 640) * this.mainContainerWidth + 'px'
                        : (16 / 1820) * this.mainContainerWidth + 'px',
            };
        },
        trainerId() {
            return +`7${this.ageGroup}`;
        },
        ageGroup() {
            if (this.yearId >= 5 && this.yearId <= 9) {
                return 2;
            }
            if (this.yearId >= 10 && this.yearId <= 11) {
                return 3;
            }
            if ((this.type === 'teacher' || this.type === 'parent') && this.passType === 'self') {
                return 3;
            }
            if (this.passType === 'several') {
                const maxAge = Math.min(...this.students.map(st => st?.year));
                if (maxAge >= 5 && maxAge <= 9) {
                    return 2;
                }
                if (maxAge >= 10 && maxAge <= 11) {
                    return 3;
                }
                return 1;
            }
            return 1;
        },
    },
    methods: {
        validateFirst() {
            const isValid =
                (this.notFromRussia ? !!this.countryId : !!this.regionId && !!this.cityId) &&
                (this.type === 'pupil' ? !!this.yearId : true);
            if (isValid) {
                this.step += 1;
            } else {
                this.showError = true;
            }
        },
        submitOrNext() {
            if (
                (this.type === 'pupil' || this.type === 'teacher' || this.type === 'parent') &&
                this.passType === 'self'
            ) {
                this.submit();
            } else {
                this.step += 0.5;
            }
        },
        submit() {
            if (this.passType !== 'self' && this.students.some(st => !st.year)) {
                this.showError = true;
            } else {
                const data = {
                    id_trainer: this.trainerId,
                    type: this.type,
                    grade: this.yearId,
                    pass_type: this.passType,
                    grades: this.passType === 'self' ? this.yearId : this.students.map(st => st.year).join(','),
                    not_from_russia: this.notFromRussia ? 1 : 0,
                    id_country: this.countryId,
                    id_region: this.regionId,
                    id_city: this.cityId,
                };
                sendQuestionnaire(data).then(response => {
                    this.$router.push({
                        path: '/',
                        query: {
                            session_id: response.session_id,
                            age: this.ageGroup,
                        },
                    });
                });
            }
        },
        addStudent() {
            this.students.push({ year: null });
        },
        deleteStudent(student) {
            this.students = this.students.filter(st => st !== student);
        },
        onClientResize(event) {
            const { offsetWidth, offsetHeight } = event[0].target;
            this.mainContainerWidth = offsetWidth;
            this.mainContainerHeight = offsetHeight;
        },
    },
    created() {
        if (this.$store.state.sessionId) {
            return this.$emit('next');
        }
        getCountryList().then(countries => (this.countries = countries));
        getRegionList().then(regions => (this.regions = regions));
    },
    mounted() {
        this.ro = new ResizeObserver(this.onClientResize);
        this.ro.observe(this.$refs.form);
    },
    beforeDestroy() {
        this.ro.unobserve(this.$refs.form);
    },

    watch: {
        regionId(val) {
            if (val) {
                getCityListInRegion(val).then(cities => (this.cities = cities));
            }
        },
    },
};
</script>
