import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            screenConfig: {},
            isImagesLoaded: false,
        };
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters('assets', ['getImage']),
        imageSrcListFromConfig() {
            throw new Error(
                `You need to implement computed "imageSrcListFromConfig" in your screen. Use "this.screenConfig" and return array like "['https://....']"`
            );
        },
        type() {
            return this.screenConfig.type;
        },
        serializedState() {
            return localStorage.getItem(`screen-${this.id}`) ?? null;
        },
        $device() {
            if (this.$mq === 'sm') return 'mobile';
            else return 'desktop';
        },
    },

    async created() {
        this.setLoader({
            isShow: true,
            message: 'Загружаем данные ...',
        });
        this.loadConfig();
        await this.loadImagesWithStore();
        this.setLoader({
            isShow: false,
            message: '',
        });
    },

    mounted() {},

    methods: {
        ...mapActions('assets', ['loadImages']),
        ...mapActions('loader', ['setLoader']),
        toNextScreen() {
            this.$emit('next');
        },
        async loadImagesWithStore() {
            try {
                await this.loadImages({
                    imageSrcList: this.imageSrcListFromConfig,
                });
                this.isImagesLoaded = true;
            } catch (error) {
                // TODO Как-то сообщить пользователю, что была ошибка при загрузке картинок
                this.isImagesLoaded = false;
                console.error(error);
            }
        },
        saveState() {
            const serializedState = JSON.stringify(this.$data);
            localStorage.setItem(`screen-${this.id}`, serializedState);
        },
        loadConfig() {
            throw new Error('The screen should load the config');
        },
        loadState() {},
        commitState() {},
    },
};
