<template>
    <svg width="33" height="42" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.4958 16.4285H25.3815V10.9428C25.3815 4.32104 22.3301 0.428467 16.2386 0.428467C10.1449 0.428467 7.09579 4.32104 7.09579 10.9428V16.4285H2.52436C1.26036 16.4285 0.238647 17.905 0.238647 19.169V36.9999C0.238647 38.2547 1.21693 39.6033 2.41236 39.9873L5.14836 40.8719C6.59956 41.2949 8.09904 41.53 9.61008 41.5713H22.8672C24.3777 41.5303 25.8766 41.2945 27.3266 40.8696L30.0604 39.985C31.2581 39.6033 32.2386 38.2547 32.2386 36.9999V19.169C32.2386 17.905 30.7575 16.4285 29.4958 16.4285ZM20.8101 16.4285H11.6672V10.0262C11.6672 6.72332 13.4889 4.9999 16.2386 4.9999C18.9884 4.9999 20.8101 6.72332 20.8101 10.0262V16.4285Z"
            fill="#DEF0F5"
        />
    </svg>
</template>

<script>
export default {
    name: 'lockedMarkerBig',
};
</script>
