<template>
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.0044 0.994141C12.5404 0.994141 0.00439453 13.5302 0.00439453 28.9941C0.00439453 44.4581 12.5404 56.9941 28.0044 56.9941C43.4684 56.9941 56.0044 44.4581 56.0044 28.9941H48.0044C48.0044 40.0398 39.0501 48.9941 28.0044 48.9941C16.9587 48.9941 8.00439 40.0398 8.00439 28.9941C8.00439 17.9484 16.9587 8.99414 28.0044 8.99414V0.994141Z"
            fill="url(#paint0_angular_2006_4814)"
        />
        <path
            d="M29.5005 5.00677C43.2617 5.55366 51.5059 17.8602 52.0152 27.2032"
            stroke="url(#paint1_linear_2006_4814)"
            stroke-width="8"
            stroke-dasharray="2 2"
        />
        <defs>
            <radialGradient
                id="paint0_angular_2006_4814"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.0044 28.9941) rotate(-90) scale(28)"
            >
                <stop stop-color="#6BB4FF" />
                <stop offset="1" stop-color="white" />
            </radialGradient>
            <linearGradient
                id="paint1_linear_2006_4814"
                x1="52.064"
                y1="26.7544"
                x2="35.0788"
                y2="-2.23238"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#91C7FF" />
                <stop offset="1" stop-color="#91C7FF" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'unlockedMarker',
};
</script>
