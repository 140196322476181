<template>
    <svg width="97" height="90" viewBox="0 0 97 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_790_86509)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M51.4285 56.2947C51.1316 56.8118 50.7035 57.2415 50.1874 57.5403C49.6714 57.8391 49.0856 57.9965 48.4893 57.9965C47.8929 57.9965 47.3072 57.8391 46.7911 57.5403C46.275 57.2415 45.8469 56.8118 45.55 56.2947L24.9845 21.303C23.6139 18.9696 25.2604 16.0034 27.9237 16.0034L69.0578 16.0034C71.7241 16.0034 73.3676 18.9726 71.997 21.303L51.4285 56.2947Z"
                fill="url(#paint0_linear_790_86509)"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_790_86509"
                x="0.490967"
                y="0.00341797"
                width="96"
                height="89.993"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_790_86509" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_790_86509" result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear_790_86509"
                x1="48.491"
                y1="56.8701"
                x2="48.491"
                y2="17.0729"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#00B372" />
                <stop offset="1" stop-color="#00C27C" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'progressMarker',
};
</script>
