import { loadImages } from '@/utils/imagePreloder';

export default {
    namespaced: true,

    state: {
        images: [],
        imageSrcTypes: [
            {
                name: 'base64',
                active: true,
            },
            { name: 'blob' },
            { name: 'baseSrc' },
        ],
        imageLoadType: [{ name: 'fetch', active: true }, { name: 'cache' }],
    },
    mutations: {
        SET_IMAGES(state, imagesData) {
            state.images.push(...imagesData);
        },
    },
    actions: {
        async loadImages({ commit, getters }, { imageSrcList = [] } = {}) {
            try {
                const filteredImageSrcList = imageSrcList.reduce((result, imageSrc) => {
                    const isImageAlreadyLoaded = !!getters.getImages.find(({ src }) => src === imageSrc);
                    if (!isImageAlreadyLoaded) {
                        result.push({ src: imageSrc });
                    }
                    return result;
                }, []);
                const loadResult = await loadImages(filteredImageSrcList);
                const images = loadResult.filter(imageData => !imageData.error);
                commit('SET_IMAGES', images);
                return images;
            } catch (e) {
                console.error(e);
            }
        },
    },
    getters: {
        imageSrcType: ({ imageSrcTypes }) => imageSrcTypes.find(type => type.active)?.name,
        imageLoadType: ({ imageLoadType }) => imageLoadType.find(type => type.active)?.name,
        getImages: ({ images }) => images,
        getImage: (state, getters) => src => {
            const image = getters.getImages.find(image => image.src === src);
            return image ? image.srcData : src;
        },
    },
};
