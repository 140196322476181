const baseUrl = 'https://xn--h1adlhdnlo2c.xn--p1ai';
const lessonId = 59;

const login = 'partner-sber';
const password = 'SB"(Nux_>3C9Tnyk';

const tokenPromise = post(baseUrl + '/api/v1/oauth', {
    login,
    password,
});

function post(url, params) {
    const req = new XMLHttpRequest();
    /* assumes we have Promise polyfilled */
    const promise = new Promise(function (res, rej) {
        req.addEventListener('load', function () {
            const response = JSON.parse(req.responseText);
            res(response);
        });
        req.addEventListener('error', () => rej(req));
    });
    req.open('POST', url);
    req.setRequestHeader('Content-type', 'application/json');
    req.send(JSON.stringify(params));
    return promise;
}

export function markFirstTaskComplete(session_id) {
    return post(baseUrl + '/trainer/markFirstTask', {
        session_id,
        markFirstTask: 1,
    });
}

export function markFullGameComplete(session_id, score, trainer_name) {
    return post(baseUrl + '/trainer/webhook', {
        session_id,
        score,
        trainer_name,
    });
}

function get(url) {
    return tokenPromise.then(function ({ token }) {
        const req = new XMLHttpRequest();
        const promise = new Promise(function (res, rej) {
            req.addEventListener('load', function () {
                const response = JSON.parse(req.responseText);
                res(response);
            });
            req.addEventListener('error', () => rej(req));
        });
        req.open('GET', url);
        req.setRequestHeader('Authorization', `Bearer ${token}`);
        req.send();
        return promise;
    });
}

export function getCountryList() {
    return get(baseUrl + '/api/v1/location/get-countries');
}

export function getRegionList() {
    return get(baseUrl + '/api/v1/location/get-regions');
}

export function getCityListInRegion(rid) {
    return get(baseUrl + '/api/v1/location/get-region-cities/' + rid);
}

export function sendQuestionnaire(data) {
    data.id_lesson = lessonId;
    return tokenPromise.then(function ({ token }) {
        const req = new XMLHttpRequest();
        const promise = new Promise(function (res, rej) {
            req.addEventListener('load', function () {
                const response = JSON.parse(req.responseText);
                res(response);
            });
            req.addEventListener('error', () => rej(req));
        });
        req.open('POST', baseUrl + '/api/v1/save-questionary');
        req.setRequestHeader('Content-type', 'application/json');
        req.setRequestHeader('Authorization', `Bearer ${token}`);
        req.send(JSON.stringify(data));
        return promise;
    });
}
