const difficulty = {
    elementary: 'junior',
    middle: 'middle',
    high: 'senior',
};

const certificate = {
    BAD: 1,
    GOOD: 2,
    EXCELLENT: 3,
};

import { markFirstTaskComplete, markFullGameComplete } from '@/api';

export const GameStore = {
    namespaced: true,
    state: {
        difficulty: 'elementary',
        sessionId: null,
        gameStarted: false,
        gameFinished: false,
        results: [],
        cert_link: 'https://xn--h1adlhdnlo2c.xn--p1ai',
    },
    mutations: {
        SET_DIFFICULTY(state, payload) {
            state.difficulty = payload;
        },
        SET_SESSION_ID(state, payload) {
            state.sessionId = payload;
        },
        SET_GAME_STARTED(state) {
            state.gameStarted = true;
        },
        SET_GAME_FINISHED(state) {
            state.gameFinished = true;
        },
        SAVE_MINIGAME_RESULT(state, payload) {
            const id = payload.id;

            const index = state.results.findIndex(r => r.id == id);
            if (index > -1) {
                state.results.splice(index, 1);
            }

            state.results.push(payload);
        },
        SAVE_CERTIFICATE_LINK(state, payload) {
            state.cert_link = payload;
        },
    },
    actions: {
        async setDifficulty({ commit, dispatch }, payload) {
            await dispatch('loadScript', `${payload}.json`, { root: true });
            commit('SET_DIFFICULTY', payload);
        },
        setSessionId({ commit }, payload) {
            commit('SET_SESSION_ID', payload);
        },
        setGameStarted({ commit }, { difficulty = 'elementary', sessionId }) {
            commit('SET_DIFFICULTY', difficulty);
            commit('SET_SESSION_ID', sessionId);
            commit('SET_GAME_STARTED');
        },
        async setGameFinished({ commit, getters }) {
            try {
                commit('SET_GAME_FINISHED');
                let res = await markFullGameComplete(
                    getters.getSessionId,
                    getters.getFinalResult,
                    difficulty[getters.getDifficulty]
                );
                commit('SAVE_CERTIFICATE_LINK', res.link);
            } catch (e) {
                console.error(e);
            }
        },
        async saveMinigameResult({ commit, getters }, payload) {
            const isEmpty = getters.getMinigameResults.length == 0;
            try {
                commit('SAVE_MINIGAME_RESULT', {
                    ...payload,
                    timestamp: Date.now(),
                });
                if (isEmpty) {
                    await markFirstTaskComplete(getters.getSessionId);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    getters: {
        getDifficulty: state => state.difficulty,
        getSessionId: state => state.sessionId,
        isGameStarted: state => state.gameStarted,
        isGameFinished: state => state.gameFinished,
        getMinigameResults: state => state.results,
        getCertificateLink: state => state.cert_link,
        getFinalResult: state => {
            if (state.difficulty == 'elementary') {
                return certificate.EXCELLENT;
            }
            const stars = state.results.reduce((acc, cur) => {
                return acc + (cur.result ?? 0);
            }, 0);
            if (stars < 11) return certificate.BAD;
            else if (stars < 16) return certificate.GOOD;
            else return certificate.EXCELLENT;
        },
    },
};
