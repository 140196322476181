<template>
    <div class="game-screen">
        <Transition name="fade" mode="out-in">
            <GameTutorial v-if="isTutorialOpen" :config="tutorialConfig" :gameId="id" @hideTutorial="showGame" />
        </Transition>
        <Transition name="fade" mode="out-in">
            <component
                v-show="!isTutorialOpen && !gameCompleted"
                :is="componentId"
                :gameConfig="screenConfig"
                :isImagesLoaded="isImagesLoaded"
                @next="$emit('next')"
                @prev="$emit('prev')"
                @image-src-list-ready="handleImageSrcList"
                @showTutorial="showTutorial"
                @gameCompleted="showResults"
            />
        </Transition>
        <Transition name="fade" mode="out-in">
            <GameResults
                v-if="gameCompleted"
                :title="results.title"
                :starsCount="results.starsCount"
                :phrase="results.phrase"
                @next="$emit('next')"
                @again="playAgain"
            />
        </Transition>
    </div>
</template>
<script>
import screenMixin from '@/mixins/screenMixin';

import { mapGetters, mapActions } from 'vuex';
import GameResults from '@/components/GameResults.vue';
import GameTutorial from '@/components/GameTutorial.vue';

export default {
    name: 'MinigameScreen',
    mixins: [screenMixin],

    data: () => ({
        tutorialConfig: null,
        imageSrcList: [],
        gameCompleted: false,
        isTutorialOpen: false,
        results: null,
    }),

    computed: {
        ...mapGetters('GameStore', ['getDifficulty']),

        componentId() {
            return this.id ? () => import(`@/components/minigames/${this.id}/Game.vue`) : null;
        },
        imageSrcListFromConfig() {
            return this.imageSrcList;
        },
        tutorialImages() {
            return (
                this.tutorialConfig?.frames?.map(
                    frame =>
                        `${require(`@/assets/games/${this.id}/tutorial/${this.getDifficulty}/${this.$device}/${frame.id}.png`)}`
                ) ?? []
            );
        },
    },

    mounted() {
        if (!process.env.VUE_APP_DISABLE_SHOW_TUTORIALS === true) {
            this.showTutorial();
        }
    },
    methods: {
        ...mapActions('GameStore', ['saveMinigameResult']),
        playAgain() {
            this.gameCompleted = false;
            this.results = null;
        },
        loadConfig() {
            this.screenConfig = require(`@/configs/${this.id}/${this.getDifficulty}.json`) ?? null;
            this.tutorialConfig = require(`@/configs/${this.id}/tutorial/${this.getDifficulty}.json`) ?? null;
        },
        showTutorial() {
            this.isTutorialOpen = true;
        },
        showGame() {
            this.isTutorialOpen = false;
        },
        showResults(data) {
            this.gameCompleted = true;
            this.saveMinigameResult({ id: this.id, result: data?.starsCount ?? 1 });
            if (data) {
                data.phrase = {
                    character: this.screenConfig.results_prefs.character,
                    text: this.screenConfig.results_prefs.phrases.find(p => p.stars == data.starsCount)?.text,
                };
                data.title = this.screenConfig.results_prefs.title;
            }
            this.results = data ?? { title: 'Результаты', starsCount: 1, phrase: { text: 'Дефолтная фраза' } };
        },
        async handleImageSrcList(data) {
            this.imageSrcList.push(...data, ...(this.tutorialImages ?? []));
            await this.loadImagesWithStore();
        },
    },

    watch: {
        isTutorialOpen(val) {
            this.$emit('darkenBackground', val);
        },
    },
    components: { GameResults, GameTutorial },
};
</script>
<style lang="scss" scoped>
.game-screen {
    box-sizing: border-box;
    position: relative;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fade-enter-active {
    transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
