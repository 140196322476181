import Vue from 'vue';
import Vuex from 'vuex';
import { ScreensStore } from './screens';
import development from './modules/development';
import assets from './modules/assets';
import loader from './modules/loader';
import { GameStore } from './modules/game';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: { ScreensStore, development, assets, loader, GameStore },
});
