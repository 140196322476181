<template>
    <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.271 1.1002C34.9622 0.442138 35.882 0.0783337 36.8363 0.0855547C37.7907 0.0927757 38.7049 0.470458 39.3861 1.13891C40.0673 1.80735 40.4621 2.71429 40.4874 3.66833C40.5126 4.62237 40.1662 5.5489 39.5213 6.25241L19.9429 30.7376C19.6063 31.1002 19.1999 31.3912 18.7483 31.5932C18.2966 31.7952 17.8088 31.904 17.3141 31.9132C16.8194 31.9224 16.3279 31.8317 15.869 31.6465C15.4102 31.4614 14.9934 31.1857 14.6435 30.8358L1.65996 17.8522C1.29839 17.5153 1.00838 17.109 0.807238 16.6576C0.606096 16.2062 0.497939 15.7188 0.48922 15.2247C0.480502 14.7306 0.571401 14.2397 0.756493 13.7815C0.941584 13.3233 1.21708 12.907 1.56654 12.5575C1.916 12.2081 2.33227 11.9326 2.79051 11.7475C3.24875 11.5624 3.73958 11.4715 4.23372 11.4802C4.72785 11.4889 5.21517 11.5971 5.6666 11.7982C6.11802 11.9994 6.52431 12.2894 6.86123 12.651L17.1362 22.921L34.1777 1.20815C34.2084 1.17038 34.2412 1.13434 34.2759 1.1002H34.271Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: 'completeMarker',
};
</script>
