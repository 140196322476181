<template>
    <ol>
        <li v-for="(screen, index) in script" :key="`dev-link-${index}`">
            <a href="#" @click="setCurrentScreen(screen.id)">{{ screen.id }}</a>
        </li>
    </ol>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('ScreensStore', ['getScript']),
        script() {
            return this.getScript?.toArray();
        },
    },
    methods: {
        ...mapActions('ScreensStore', ['setCurrentScreen']),
    },
};
</script>
