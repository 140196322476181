var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'screen-controller',
        {
            'screen-controller--darken': _vm.darkBackground,
            'screen-controller--overflow-y': _vm.currentScreen?.type === 'comics',
        },
    ]},[_c('div',{ref:"screenContainer",staticClass:"screen-container"},[_c('Transition',{attrs:{"name":"screen","duration":600}},[(_vm.currentScreen)?_c(_vm.screenComponent,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowLoader),expression:"!isShowLoader"}],key:`${_vm.getDifficulty}/${_vm.currentScreen.id}`,tag:"component",style:(_vm.mainContainerStyle),attrs:{"id":_vm.currentScreen.id},on:{"next":_vm.nextScreen,"prev":_vm.previousScreen,"darkenBackground":_vm.darkenBackground}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }