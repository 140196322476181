<template>
    <div class="tutorial" :style="frameBackground">
        <div class="tutorial__content"></div>
        <div class="tutorial__controls">
            <button class="button--round question-button" :disabled="!currentFrame?.helpEnabled">
                <div class="button__icon icon icon__question"></div>
            </button>
            <CharacterCard :characterName="'teacher'" :characterPhrase="currentFrame?.text">
                <div class="tutorial-buttons">
                    <button class="button" @click="previous" v-if="hasPrevious">Назад</button>
                    <button class="button--primary" v-if="hasNext" @click="next">Далее</button>
                    <button class="button--primary" v-if="!hasNext" @click="$emit('hideTutorial')">К игре!</button>
                </div>
            </CharacterCard>
        </div>
    </div>
</template>

<script>
import { DoubleLinkedList } from 'double-linkedlist';
import { mapGetters } from 'vuex';
import CharacterCard from '@/components/cards/CharacterCard.vue';
export default {
    components: {
        CharacterCard,
    },
    props: {
        gameId: {
            type: String,
            require: true,
        },
        config: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            frames: null,
            currentFrame: null,
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapGetters('GameStore', ['getDifficulty']),
        ...mapGetters('assets', ['getImage']),
        hasNext() {
            return !!this.currentFrame?.getNext();
        },
        hasPrevious() {
            return !!this.currentFrame?.getPrevious();
        },
        frameBackground() {
            if (this.currentFrame) {
                return {
                    backgroundImage: `url(${this.getImage(
                        `${require(`@/assets/games/${this.gameId}/tutorial/${this.getDifficulty}/${this.$device}/${this.currentFrame?.id}.png`)}`
                    )})`,
                };
            }
            return '';
        },
        $device() {
            if (this.$mq === 'sm') return 'mobile';
            else return 'desktop';
        },
    },
    methods: {
        init() {
            this.frames = new DoubleLinkedList();
            this.config.frames.map(frame => this.frames.insertAtEnd({ ...frame }));
            this.currentFrame = this.frames.getHead();
        },

        next() {
            if (this.hasNext) this.currentFrame = this.currentFrame.getNext();
        },

        previous() {
            if (this.hasPrevious) this.currentFrame = this.currentFrame.getPrevious();
        },

        showHelpText() {},
    },
};
</script>
<style lang="scss" scoped>
.tutorial {
    width: 100%;
    height: 100%;
    // border: 1px solid yellow;
    --backgroundOffsetX: 3.625em;
    // --backgroundOffsetX: 0em;
    display: flex;
    flex-direction: column;
    background-size: calc(100% + var(--backgroundOffsetX) * 2);
    background-position-x: calc(-1 * var(--backgroundOffsetX));
    background-position-y: calc(-0.5625 * var(--backgroundOffsetX));
    background-repeat: no-repeat;
    &__content {
        height: 85%;
    }

    &__controls {
        height: 15%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .question-button {
            // pointer-events: none;
            margin-top: 0.75em;
            margin-right: 1em;
        }

        .tutorial-buttons {
            display: flex;
            & > * {
                margin-left: 0.5em;
            }
        }
    }

    @media (max-width: 900px) {
        --backgroundOffsetX: 0px;

        &__controls {
            .question-button {
                margin-top: 0.5em;
            }

            .tutorial-buttons {
                & > * {
                    margin-left: 0.5em;
                }
            }
        }
    }
}
</style>
