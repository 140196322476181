<template>
    <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.1805 12.8308H19.0948V8.71652C19.0948 3.75024 16.8062 0.830811 12.2377 0.830811C7.66739 0.830811 5.38053 3.75024 5.38053 8.71652V12.8308H1.95196C1.00396 12.8308 0.237671 13.9382 0.237671 14.8862V28.2594C0.237671 29.2005 0.971385 30.212 1.86796 30.5L3.91996 31.1634C5.00836 31.4806 6.13297 31.6569 7.26624 31.688H17.2091C18.342 31.6572 19.4661 31.4803 20.5537 31.1617L22.604 30.4982C23.5022 30.212 24.2377 29.2005 24.2377 28.2594V14.8862C24.2377 13.9382 23.1268 12.8308 22.1805 12.8308ZM15.6662 12.8308H8.8091V8.0291C8.8091 5.55195 10.1754 4.25938 12.2377 4.25938C14.3 4.25938 15.6662 5.55195 15.6662 8.0291V12.8308Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: 'lockedMarkerSmall',
};
</script>
