<template>
    <div class="star" ref="star">
        <svg
            width="134"
            height="128"
            viewBox="0 0 134 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="star-outer"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.4525 3.58064C63.638 -0.9644 70.3708 -0.963863 73.5556 3.5817L92.4276 30.5173C93.4508 31.9778 94.9327 33.0545 96.6378 33.5765L128.083 43.2024C133.39 44.8271 135.471 51.2307 132.132 55.6646L112.348 81.9361C111.275 83.3607 110.709 85.1029 110.74 86.886L111.304 119.769C111.4 125.319 105.952 129.277 100.703 127.472L69.6066 116.776C67.9205 116.196 66.0891 116.196 64.403 116.776L33.2963 127.473C28.0475 129.278 22.6006 125.32 22.6959 119.77L23.2606 86.886C23.2912 85.1029 22.7251 83.3607 21.6523 81.9361L1.86822 55.6646C-1.47073 51.2307 0.609782 44.8271 5.91713 43.2024L37.3631 33.5762C39.0678 33.0544 40.5494 31.9781 41.5726 30.5182L60.4525 3.58064Z"
                fill="url(#paint0_linear_961_435)"
                class="star-outer-path"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_961_435"
                    x1="67.0001"
                    y1="-5.76807"
                    x2="67.0001"
                    y2="131.186"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#9BB1FF" />
                    <stop offset="0.651042" stop-color="#8BA5FF" />
                    <stop offset="1" stop-color="#9BB1FF" />
                </linearGradient>
            </defs>
        </svg>

        <svg
            width="106"
            height="102"
            viewBox="0 0 106 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="star-inner"
        >
            <g filter="url(#filter0_i_961_104)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.4516 3.89557C49.6371 -0.64947 56.3699 -0.648923 59.5547 3.89664L73.1763 23.3386C74.1996 24.799 75.6814 25.8757 77.3866 26.3977L100.083 33.3455C105.39 34.9702 107.471 41.3738 104.132 45.8076L89.8522 64.77C88.7794 66.1946 88.2133 67.9368 88.244 69.7199L88.6515 93.4545C88.7468 99.0044 83.2995 102.962 78.0506 101.157L55.6057 93.4367C53.9196 92.8568 52.0882 92.8567 50.4021 93.4365L27.9491 101.158C22.7004 102.963 17.2534 99.0049 17.3487 93.4553L17.7563 69.7199C17.7869 67.9368 17.2209 66.1946 16.1481 64.77L1.86822 45.8076C-1.47073 41.3738 0.60978 34.9702 5.91713 33.3455L28.6144 26.3975C30.3191 25.8756 31.8006 24.7993 32.8239 23.3394L46.4516 3.89557Z"
                    fill="url(#paint0_linear_961_104)"
                />
            </g>
            <path
                d="M57.9167 5.04426L71.5384 24.4862C72.8174 26.3117 74.6697 27.6576 76.8011 28.3101L99.4977 35.2579C103.478 36.4764 105.039 41.2791 102.534 44.6045L88.2545 63.5669C86.9135 65.3476 86.206 67.5254 86.2442 69.7542L86.6518 93.4889C86.7233 97.6512 82.6378 100.62 78.7012 99.2656L56.2562 91.5454C54.1486 90.8205 51.8593 90.8204 49.7517 91.5452L27.2987 99.2666C23.3621 100.62 19.2769 97.6519 19.3484 93.4897L19.756 69.7542C19.7943 67.5254 19.0867 65.3476 17.7457 63.5669L3.46587 44.6045C0.961653 41.2791 2.52204 36.4764 6.50255 35.2579L29.1998 28.3099C31.3307 27.6576 33.1826 26.3122 34.4616 24.4873L48.0894 5.04346C50.4785 1.63468 55.5281 1.63509 57.9167 5.04426Z"
                stroke="url(#paint1_linear_961_104)"
                stroke-width="4"
            />
            <defs>
                <filter
                    id="filter0_i_961_104"
                    x="0.254395"
                    y="0.487122"
                    width="105.491"
                    height="103.112"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.345098 0 0 0 0 0.47451 0 0 0 0 0.917647 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_961_104" />
                </filter>
                <linearGradient
                    id="paint0_linear_961_104"
                    x1="53.0001"
                    y1="-5.45313"
                    x2="53.0001"
                    y2="104.871"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#9BB1FF" />
                    <stop offset="0.651042" stop-color="#8BA5FF" />
                    <stop offset="1" stop-color="#9BB1FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_961_104"
                    x1="53.0001"
                    y1="-2.49389"
                    x2="53.0001"
                    y2="102.061"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#ACBEFF" />
                    <stop offset="0.0494792" stop-color="#C5D1FF" />
                    <stop offset="0.950521" stop-color="#D7DFFF" />
                    <stop offset="1" stop-color="#C5D1FF" />
                </linearGradient>
            </defs>
        </svg>
        <transition name="fill-fade">
            <svg
                width="122"
                height="120"
                viewBox="0 0 122 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="star-inner-fill"
            >
                <g filter="url(#filter0_d_908_116554)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M54.4516 9.66364C57.6371 5.1186 64.3699 5.11914 67.5547 9.66471L81.1763 29.1066C82.1996 30.5671 83.6814 31.6438 85.3866 32.1658L108.083 39.1136C113.39 40.7383 115.471 47.1419 112.132 51.5757L97.8522 70.5381C96.7794 71.9627 96.2133 73.7049 96.2439 75.488L96.6515 99.2226C96.7468 104.772 91.2995 108.73 86.0506 106.925L63.6057 99.2048C61.9196 98.6248 60.0882 98.6248 58.4021 99.2046L35.9491 106.926C30.7004 108.731 25.2534 104.773 25.3487 99.2234L25.7563 75.488C25.7869 73.7049 25.2209 71.9627 24.1481 70.5381L9.86822 51.5757C6.52927 47.1419 8.60978 40.7383 13.9171 39.1136L36.6144 32.1655C38.3191 31.6437 39.8006 30.5674 40.8239 29.1075L54.4516 9.66364Z"
                        fill="url(#paint0_radial_908_116554)"
                    />
                    <path
                        d="M56.0894 10.8115C58.4785 7.40275 63.5281 7.40316 65.9167 10.8123L79.5384 30.2543C80.8174 32.0798 82.6697 33.4257 84.8011 34.0782L107.498 41.026C111.478 42.2445 113.039 47.0472 110.534 50.3726L96.2545 69.335C94.9135 71.1157 94.206 73.2934 94.2442 75.5223L94.6518 99.2569C94.7233 103.419 90.6378 106.388 86.7012 105.034L64.2562 97.3135C62.1486 96.5886 59.8593 96.5885 57.7517 97.3133L35.2987 105.035C31.3621 106.388 27.2769 103.42 27.3484 99.2577L27.756 75.5223C27.7943 73.2934 27.0867 71.1157 25.7457 69.335L11.4659 50.3726C8.96165 47.0472 10.522 42.2445 14.5025 41.026L37.1998 34.0779C39.3307 33.4256 41.1826 32.0803 42.4616 30.2554L56.0894 10.8115Z"
                        stroke="url(#paint1_linear_908_116554)"
                        stroke-width="4"
                    />
                </g>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.6309 73.6116C29.3411 71.6324 28.5592 69.7461 27.3435 68.1318L13.0636 49.1694C11.3941 46.9525 12.4344 43.7507 15.0881 42.9383L37.7853 35.9903C40.3424 35.2075 42.5647 33.5931 44.0995 31.4032L57.7273 11.9594C59.3201 9.68683 62.6864 9.6871 64.2788 11.9599L77.9005 31.4018C79.4353 33.5925 81.6581 35.2075 84.2158 35.9905L92.8205 38.6245C82.0219 58.1451 58.0061 72.1235 29.6309 73.6116Z"
                    fill="url(#paint2_linear_908_116554)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.0659 44.8701C28.0908 45.4302 26.2031 45.9656 24.696 46.2779C22.9141 46.6472 21.9323 47.1287 21.4141 47.5399C20.943 47.9138 20.8584 48.2201 20.8419 48.4291C20.8201 48.7034 20.9062 49.0475 21.101 49.4315C21.2875 49.7992 21.5206 50.0992 21.6519 50.2558L27.6479 57.2342L25.3724 59.1893L19.371 52.2045L19.3608 52.1925C19.1564 51.9495 18.76 51.4481 18.4255 50.7886C18.097 50.1409 17.7694 49.2258 17.8512 48.1922C17.9383 47.0932 18.4745 46.0429 19.5492 45.19C20.5769 44.3745 22.0658 43.7592 24.0873 43.3403C25.4831 43.0511 27.2685 42.5449 29.2906 41.9716C29.4282 41.9326 29.5669 41.8933 29.7066 41.8537C31.8695 41.2411 34.2477 40.5762 36.5266 40.0898L37.1527 43.0238C34.9836 43.4867 32.6949 44.1253 30.5242 44.7402C30.371 44.7836 30.2182 44.8269 30.0659 44.8701Z"
                    fill="url(#paint3_linear_908_116554)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M36.8205 84.5948C36.7429 86.6464 36.6687 88.607 36.5 90.1369C36.3005 91.9457 36.4551 93.0283 36.6861 93.6482C36.8961 94.2118 37.1613 94.3869 37.3549 94.4672C37.6091 94.5726 37.9629 94.5971 38.3883 94.5305C38.7957 94.4667 39.153 94.3377 39.3425 94.2613L47.8323 90.7152L48.9885 93.4835L40.491 97.0327L40.4765 97.0387C40.1822 97.158 39.5828 97.3801 38.8522 97.4944C38.1347 97.6067 37.1632 97.6355 36.2055 97.2383C35.1872 96.8159 34.3539 95.9813 33.8749 94.6956C33.4168 93.4663 33.2918 91.8601 33.5181 89.8081C33.6743 88.3912 33.7447 86.5368 33.8243 84.4365C33.8297 84.2936 33.8352 84.1495 33.8407 84.0044C33.9264 81.7581 34.029 79.2908 34.2707 76.9732L37.2545 77.2843C37.0244 79.4903 36.9245 81.8643 36.8385 84.1187C36.8325 84.2779 36.8265 84.4366 36.8205 84.5948Z"
                    fill="url(#paint4_linear_908_116554)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M76.5463 92.5943C78.4735 93.3021 80.3153 93.9785 81.7182 94.6117C83.3768 95.3603 84.4542 95.5479 85.1151 95.5198C85.716 95.4942 85.9645 95.2961 86.1007 95.1368C86.2795 94.9276 86.4121 94.5986 86.4803 94.1735C86.5455 93.7664 86.5332 93.3867 86.5191 93.1828L85.7701 84.0128L88.7601 83.7686L89.5098 92.9469L89.511 92.9626C89.5335 93.2794 89.5595 93.918 89.4425 94.6482C89.3275 95.3653 89.0547 96.2982 88.381 97.0862C87.6646 97.9242 86.6133 98.4587 85.2426 98.517C83.9318 98.5728 82.3656 98.1954 80.484 97.3461C79.1848 96.7596 77.4428 96.1197 75.4699 95.3949C75.3357 95.3456 75.2004 95.2959 75.064 95.2458C72.9542 94.4701 70.6393 93.6101 68.5098 92.6641L69.7278 89.9225C71.7547 90.8229 73.9817 91.6516 76.0992 92.43C76.2486 92.4849 76.3977 92.5397 76.5463 92.5943Z"
                    fill="url(#paint5_linear_908_116554)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M96.5408 56.0432C97.8095 54.4291 99.022 52.8864 100.058 51.7479C101.282 50.4018 101.794 49.4351 101.971 48.7978C102.132 48.2184 102.021 47.9209 101.911 47.7421C101.768 47.5074 101.496 47.2797 101.113 47.0835C100.745 46.8956 100.381 46.79 100.182 46.7404L91.2296 44.6191L91.9213 41.6999L100.882 43.8232L100.897 43.8269C101.206 43.9034 101.821 44.076 102.479 44.413C103.126 44.7439 103.929 45.2916 104.47 46.1759C105.046 47.1162 105.229 48.2812 104.861 49.6028C104.509 50.8666 103.666 52.2395 102.277 53.7666C101.318 54.8211 100.171 56.28 98.8718 57.9324C98.7834 58.0448 98.6943 58.1581 98.6045 58.2723C97.2148 60.0392 95.6816 61.975 94.1238 63.708L91.8927 61.7024C93.3755 60.0529 94.8517 58.191 96.2464 56.4177C96.3449 56.2925 96.443 56.1677 96.5408 56.0432Z"
                    fill="url(#paint6_linear_908_116554)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M67.2355 26.4645C66.0924 24.7592 64.9999 23.1293 64.2372 21.7925C63.3354 20.2119 62.574 19.4269 62.0228 19.0612C61.5216 18.7287 61.2041 18.7429 61.0003 18.7917C60.7327 18.8558 60.432 19.044 60.127 19.3479C59.8349 19.6389 59.6217 19.9534 59.5133 20.1266L54.7293 27.9856L52.1667 26.4256L56.9551 18.5595L56.9634 18.5461C57.1313 18.2766 57.4857 17.7447 58.0096 17.2227C58.5241 16.7101 59.2931 16.1158 60.3014 15.8743C61.3735 15.6174 62.5382 15.8029 63.6814 16.5614C64.7746 17.2867 65.8198 18.5127 66.8429 20.3058C67.5493 21.5439 68.5824 23.0855 69.7525 24.8315C69.8321 24.9503 69.9124 25.0701 69.9932 25.1907C71.2442 27.0584 72.6115 29.1148 73.7782 31.1318L71.1814 32.634C70.0708 30.714 68.7562 28.7347 67.5007 26.8603C67.4121 26.728 67.3236 26.596 67.2355 26.4645Z"
                    fill="url(#paint7_linear_908_116554)"
                />
                <path
                    d="M42.826 32.6794L51.0687 25.487L41.5653 30.9057L42.3071 19.9909L39.419 30.5434L32.2256 22.3008L37.6446 31.8046L26.7295 31.0624L37.2814 33.9505L29.0394 41.1439L38.542 35.7242L37.8008 46.6397L40.689 36.0882L47.8825 44.3301L42.4635 34.827L53.3784 35.5682L42.826 32.6794Z"
                    fill="white"
                />
                <defs>
                    <filter
                        id="filter0_d_908_116554"
                        x="0.254395"
                        y="2.25519"
                        width="121.491"
                        height="117.112"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.9125 0 0 0 0 0.657 0 0 0 0 0 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_116554" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_116554" result="shape" />
                    </filter>
                    <radialGradient
                        id="paint0_radial_908_116554"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(63.6882 47.5799) rotate(97.1077) scale(60.1712 63.2671)"
                    >
                        <stop stop-color="#FFF3C7" />
                        <stop offset="0.286458" stop-color="#FFE68E" />
                        <stop offset="0.814756" stop-color="#F9CA26" />
                        <stop offset="1" stop-color="#FFDF71" />
                    </radialGradient>
                    <linearGradient
                        id="paint1_linear_908_116554"
                        x1="61.0001"
                        y1="0.314941"
                        x2="61.0001"
                        y2="106.87"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFF9E4" />
                        <stop offset="0.748726" stop-color="#FFE27A" />
                        <stop offset="1" stop-color="#FFF6D8" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_908_116554"
                        x1="47.5298"
                        y1="30.7792"
                        x2="68.1277"
                        y2="73.6116"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" stop-opacity="0.3" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_908_116554"
                        x1="13.1962"
                        y1="44.5944"
                        x2="26.5061"
                        y2="54.6223"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FF9900" />
                        <stop offset="1" stop-color="#FFC700" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_908_116554"
                        x1="31.3453"
                        y1="100.554"
                        x2="44.9954"
                        y2="90.994"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFEFB7" />
                        <stop offset="1" stop-color="#FFEFB7" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_908_116554"
                        x1="90.0322"
                        y1="102.733"
                        x2="85.1585"
                        y2="86.797"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFEFB7" />
                        <stop offset="1" stop-color="#FFEFB7" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_908_116554"
                        x1="110.351"
                        y1="46.3504"
                        x2="93.6886"
                        y2="46.061"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F3B130" />
                        <stop offset="1" stop-color="#F3B130" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_908_116554"
                        x1="62.2846"
                        y1="10.3353"
                        x2="56.8605"
                        y2="26.0925"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFEFB7" />
                        <stop offset="1" stop-color="#FFEFB7" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </transition>
        <Particles
            :id="`tsparticles-${index}`"
            class="particles"
            :particlesInit="particlesInit"
            :options="particlesConfig"
        />
    </div>
</template>
<script>
import { loadFull } from 'tsparticles';
/**
 * @category GameResults
 * @module GameResults/GameResultsStar
 *
 * @desc Компонент кадра комикса
 * @vue-prop {String} achieved - Заполнена ли звезда
 * @vue-prop {String} index - Индекс звезды
 * @vue-prop {String} animationTimeout - Таймаут анимации звезды
 *
 */
export default {
    name: 'GameResultsStar',
    props: {
        achieved: { type: Boolean, required: false, default: false },
        index: { type: Number, required: true, default: 0 },
        animationTimeout: { type: Number, required: false, default: 500 },
    },
    /**
     * При монтировании компонента проверяет является ли звезда полученной
     * если да, запускает анимацию заполнения звезды
     */
    mounted() {
        if (this.achieved && this.$refs.star) {
            setTimeout(() => this.$refs.star.classList.add('filled'), this.animationTimeout);
        }
    },
    computed: {
        /**
         * Конфигурация для анимации чатсиц tsparticles
         * @returns {any}
         */
        particlesConfig() {
            return {
                background: {
                    color: {
                        value: 'transparent',
                    },

                    size: {
                        value: '100%',
                    },
                },
                fullScreen: {
                    enable: false,
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                        },
                        onHover: {
                            enable: false,
                        },
                        resize: true,
                    },
                },
                particles: {
                    color: {
                        value: ['#FFF', '#EDB300', '#FBD655'],
                    },
                    links: {
                        color: ['#FFF', '#EDB300'],
                        distance: 100,
                        enable: false,
                        opacity: 0.4,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: ['right', 'top', 'left'],
                        enable: true,
                        outMode: 'bounce',
                        random: true,
                        speed: 7,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 150,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.7,
                    },
                    shape: {
                        type: ['star', 'triangle'],
                        star: {
                            random: true,
                            inset: 2,
                        },
                    },
                    size: {
                        random: true,
                        value: 7,
                    },
                },
                detectRetina: true,
            };
        },
    },
    methods: {
        /**
         * Метод инициализации анимации частиц
         * @param {any} engine
         */
        async particlesInit(engine) {
            await loadFull(engine);
        },
    },
};
</script>

<style lang="scss" scoped>
.particles {
    width: 8.5em;
    height: 8.5em;
    position: absolute;
    top: 0.4em;
    z-index: 1;
    display: none;
    @media (max-width: 900px) {
        width: 3em;
        height: 3em;
    }
}
.star {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8em;
    height: 8em;
    @media (max-width: 900px) {
        width: 3em;
        height: 3em;
    }

    &-outer {
        position: absolute;
        display: block;
        width: 100%;
        -webkit-filter: drop-shadow(0.25em 0.25em 0.3em rgba(81, 114, 237, 0.5));
        filter: drop-shadow(0.25em 0.45em 0.3em rgba(81, 114, 237, 0.5));
        /* Similar syntax to box-shadow */
        transition: all 1s;
        &-path {
            transition: fill 0.4s ease;
        }
    }
    &-inner {
        margin-top: 0.1em;
        position: absolute;
        width: 90%;
        opacity: 1;
        display: block;
        z-index: 9;
        transition: all 2s;
    }

    &-inner-fill {
        margin-top: 0.38em;
        position: absolute;
        opacity: 0;
        transition: all 0.2s ease;
        z-index: 999;
        width: 100%;
        right: 3em;
    }
}

.filled {
    transition: all 1s;
    .particles {
        display: block;
    }
    .star-inner-fill {
        opacity: 1;
        display: block;
        z-index: 9999;
        right: 0;
    }
    .star-outer-path {
        animation: setfilled 1s forwards;
    }
    .star-inner {
        opacity: 0;
    }
}
@keyframes setfilled {
    0%,
    10% {
        fill: transparent;
    }
    100% {
        fill: #ffdf6e;
    }
}
</style>
