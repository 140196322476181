<template>
    <div
        v-if="isImagesLoaded && this.currentScreen"
        class="dialog-screen"
        :style="{ backgroundImage: `url(${getImage(backgroundImage)})` }"
    >
        <div class="dialog-container">
            <div class="chars-area">
                <div class="chars-container">
                    <transition name="fade-left" :duration="{ enter: 200, leave: 400 }" appear>
                        <div class="chars-left" :key="`chars-left-${currentScreen.leftImage}`">
                            <img :src="getImage(currentScreen.leftImage)" alt="" />
                        </div>
                    </transition>
                    <transition name="fade-right" :duration="{ enter: 200, leave: 400 }" appear>
                        <div class="chars-right" :key="`chars-right-${currentScreen.rightImage}`">
                            <img :src="getImage(currentScreen.rightImage)" alt="" />
                        </div>
                    </transition>
                </div>
            </div>
            <div class="phrase-container">
                <CharacterCard
                    :characterName="currentScreen.characterFace"
                    :characterPhrase="currentScreen.characterText"
                    :forDialog="true"
                    :key="`phrase-${currentScreen.id}`"
                />

                <div class="phrase-buttons">
                    <button
                        class="button button-prev"
                        @click="previousScreen"
                        :disabled="!this.currentScreen.getPrevious()"
                    >
                        Назад
                    </button>

                    <button class="button--primary button-next" @click="nextScreen">
                        {{ !this.currentScreen.getNext() ? screenConfig.lastButtonText : 'Далее' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import screenMixin from '@/mixins/screenMixin';
import { DoubleLinkedList } from 'double-linkedlist';
import { mapActions, mapGetters } from 'vuex';
import CharacterCard from '@/components/cards/CharacterCard.vue';
/**
 * @category DialogScreen
 * @module DialogScreen
 *
 * @desc Главный компонент сюжетных экранов.
 * @vue-prop {Object} screenConfig Конфиг игры
 * @vue-prop {boolean} isImagesLoaded Флаг который показывает загрузились ли все ассеты
 *
 * @vue-data {Object|null} screens - последовательность экранов
 * @vue-data {Object|null} currentScreen - Текущий экран
 *
 * @vue-computed {Array} imageSrcListFromConfig - Возвращает список картинок для загрузчика
 * @vue-computed {String} backgroundImage - Возвращает исходник фона
 */
export default {
    name: 'DialogScreen',
    components: { CharacterCard },
    mixins: [screenMixin],
    data: () => ({
        currentScreen: null,
        screens: null,
    }),
    computed: {
        ...mapGetters('GameStore', ['getDifficulty']),
        ...mapGetters('assets', ['getImage']),

        imageSrcListFromConfig() {
            const imagesArray = [
                ...this.screens
                    .toArray()
                    .map(screen => {
                        return [screen.leftImage, screen.rightImage];
                    })
                    .flat()
                    .filter(el => el !== null),
            ];

            imagesArray.push(require(`@/assets/dialogs/bg.png`));

            return imagesArray;
        },

        backgroundImage() {
            return this.getImage(require(`@/assets/dialogs/bg.png`));
        },
    },

    methods: {
        ...mapActions('ScreensStore', ['nextScreen']),
        /**
         * Инициализирует конфигурацию экрана
         */
        loadConfig() {
            this.screenConfig = require(`@/configs/${this.id}/${this.getDifficulty}.json`);

            const screensLinkedList = new DoubleLinkedList();

            this.screenConfig.screens = this.screenConfig.screens.map(screen => {
                let containers = { leftImage: null, rightImage: null };

                containers = {
                    leftImage: require(`@/assets/dialogs/${screen.id}/left.png`),
                    rightImage: require(`@/assets/dialogs/${screen.id}/right.png`),
                };

                const finalScreenObject = { ...screen, ...containers };
                screensLinkedList.insertAtEnd(finalScreenObject);
                return finalScreenObject;
            });

            this.screens = screensLinkedList;
            this.currentScreen = this.screens.getHead();
        },
        /**
         * Переводит на следующий экран
         */
        nextScreen() {
            const nextScreen = this.currentScreen.getNext();
            if (nextScreen) {
                this.currentScreen = nextScreen;
            } else {
                this.$emit('next');
            }
        },
        /**
         * Переводит на предыдущий
         */
        previousScreen() {
            const previousScreen = this.currentScreen.getPrevious();
            if (previousScreen) {
                this.currentScreen = previousScreen;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fade-left-enter-active {
    transition: all 0.5s;
}
.fade-left-leave-active {
    transition: all 1.5s;
}

.fade-left-enter {
    opacity: 0;
}

.fade-left-leave-to {
    opacity: 0;
}

.fade-right-enter-active {
    transition: all 0.5s;
}
.fade-right-leave-active {
    transition: all 1.5s;
}

.fade-right-enter {
    opacity: 0;
}

.fade-right-leave-to {
    opacity: 0;
}

.dialog-screen {
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chars-area {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    .chars-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .chars-left {
        width: 37.5em;
        margin-top: -3.5%;
        position: absolute;
        @media (max-width: 900px) {
            width: 13.5em;
        }
        img {
            width: 100%;
        }
    }

    .chars-right {
        width: 37.5em;
        position: absolute;
        right: 0;
        @media (max-width: 900px) {
            width: 13.5em;
        }
        img {
            width: 100%;
        }
    }
}
.dialog-container {
    height: 100%;
    width: 75.1%;
    display: flex;
    padding-bottom: 2%;
    justify-content: space-between;
    position: relative;
}

.phrase-container {
    z-index: 1;
    padding: 9%;
    height: 29%;
    margin-top: auto;
    margin-bottom: 4%;
    flex: 1 100%;
    background: #fff;
    padding-top: 1.5%;
    border-radius: 2.8em;
    box-shadow: 0px 0.25em 2em rgba(96, 128, 136, 0.4);
    padding-bottom: 5%;
    @media (max-width: 900px) {
        border-radius: 1em;
    }
}

.phrase-buttons {
    display: flex;
    justify-content: center;
    margin-top: 4%;
}
.button-prev {
    margin-right: 2%;
}
</style>
