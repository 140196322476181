import Vue from 'vue';
import VueRouter from 'vue-router';
import ScreenController from '@/views/ScreenController';
import RegisterForm from '@/views/RegisterForm';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'screen-controller',
        component: ScreenController,
        beforeEnter: async (to, from, next) => {
            const sessionId = to.query.session_id ?? store.getters['GameStore/getSessionId'];
            const difficulty = difficultyDict[to.query.age] ?? store.getters['GameStore/getDifficulty'];

            if (sessionId) {
                await store.dispatch('GameStore/setGameStarted', {
                    difficulty: difficulty ?? difficultyDict[1],
                    sessionId: sessionId,
                });
                return next();
            } else {
                return next({
                    name: 'form',
                });
            }
        },
    },
    {
        path: '/form',
        name: 'form',
        component: RegisterForm,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

if ('VUE_APP_DEFAULT_SESSION_ID' in process.env) {
    store.dispatch('GameStore/setSessionId', process.env.VUE_APP_DEFAULT_SESSION_ID);
}

const difficultyDict = {
    1: 'elementary',
    2: 'middle',
    3: 'high',
};

export default router;
