<template>
    <div
        class="character-card"
        :class="{
            'for-dialog': forDialog,
        }"
    >
        <div class="user-pic">
            <img :src="characterPic" />
        </div>
        <div class="text-container">
            <div class="title" v-if="showName">{{ characterNameVerbose }}</div>
            <div class="phrase" :class="{ 'crop-if-character-name': showName }">
                {{ characterPhrase }}
            </div>
        </div>
        <div class="button-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/**
 * @category Cards
 * @module CharacterCard
 * @desc Компонет с фразой персонажа внизу экрана
 *
 * @vue-prop {string} characterName Имя персонажа
 * @vue-prop {string} characterPhrase Реплика персонажа
 * @vue-prop {boolean} showName Показывать ли реплику персонажа
 *
 * @vue-computed getImage возвращает изображение загруженное в Vuex
 * @vue-computed {string} characterPic возвращает картинку-аватарку персонажа
 * @vue-computed {string} characterNameVerbose возвращает имя персонажа на русском языке
 */
import { mapGetters } from 'vuex';
/**
 * Массив с конфигами возможных изображений персонажа
 */
const characterConfig = [
    {
        name: 'zapyatynya',
        nameVerbose: 'Запятыня',
        picSrc: require('@/assets/charactersPics/zapyatynya.svg'),
    },
    {
        name: 'skobez',
        nameVerbose: 'Скобец',
        picSrc: require('@/assets/charactersPics/skobez.svg'),
    },
    {
        name: 'slash',
        nameVerbose: 'Слеш',
        picSrc: require('@/assets/charactersPics/slash.svg'),
    },
    {
        name: 'teacher',
        nameVerbose: 'Учительница',
        picSrc: require('@/assets/charactersPics/teacher.svg'),
    },
];

export default {
    name: 'CharacterCard',
    props: {
        characterName: {
            type: String,
            default: 'zapyatynya',
        },
        characterPhrase: {
            type: String,
            default: 'Реплика персонажа',
        },
        showName: {
            type: Boolean,
            default: false,
        },

        forDialog: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('assets', ['getImage']),
        characterPic() {
            return characterConfig.find(c => c.name == this.characterName)?.picSrc;
        },
        characterNameVerbose() {
            return characterConfig.find(c => c.name == this.characterName)?.nameVerbose;
        },
    },
};
</script>

<style lang="scss" scoped>
.for-dialog {
    flex-wrap: wrap;
    box-shadow: none !important;
    height: 60%;
    .phrase {
        overflow: visible !important;
        text-overflow: clip !important;
        -webkit-line-clamp: unset !important;
    }
}
.character-card {
    padding: 1em;

    width: 99%;
    max-width: 99%;
    background: white;
    border-radius: 2.75em;

    display: flex;
    align-items: center;
    box-shadow: 0px 0.25em 2em rgba(129, 174, 186, 0.4);

    .user-pic {
        width: 5.25em;
        height: 5.25em;
        border-radius: 50%;
        background: lightblue;
        position: relative;
        flex: 0 0 auto;
        img {
            width: 143%;
            height: 143%;
            object-fit: contain;
            object-position: right bottom;
            transform: translate(-30%, -30%);
        }
    }
    .text-container {
        margin-left: 1.25em;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;

        .title {
            font-weight: 400;
            font-size: 0.875em;
            line-height: 150%;
            letter-spacing: -0.011em;
            color: #3a3eaf;
        }
        .phrase {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 1.75em;
            line-height: 114%;
            color: #262c2e;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;

            &.crop-if-character-name {
                -webkit-line-clamp: 1;
            }
        }
    }
    .button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    @media (max-width: 900px) {
        padding: 0.375em;
        border-radius: 1em;

        box-shadow: 0px 0.25em 2em rgba(129, 174, 186, 0.4);

        .user-pic {
            width: 1.75em;
            height: 1.75em;
        }
        .text-container {
            margin-left: 0.4em;
            .title {
                font-size: 0.3em;
            }
            .phrase {
                font-size: 0.6em;
            }
        }
    }
}
</style>
