<template>
    <div
        :class="[
            'screen-controller',
            {
                'screen-controller--darken': darkBackground,
                'screen-controller--overflow-y': currentScreen?.type === 'comics',
            },
        ]"
    >
        <div class="screen-container" ref="screenContainer">
            <Transition name="screen" :duration="600">
                <component
                    v-if="currentScreen"
                    :is="screenComponent"
                    :id="currentScreen.id"
                    :key="`${getDifficulty}/${currentScreen.id}`"
                    :style="mainContainerStyle"
                    @next="nextScreen"
                    @prev="previousScreen"
                    @darkenBackground="darkenBackground"
                    v-show="!isShowLoader"
                />
            </Transition>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ComicsScreen from '@/screens/ComicsScreen';
import DialogScreen from '@/screens/DialogScreen';
import MinigameScreen from '@/screens/MinigameScreen.vue';
import ProgressScreen from '@/screens/ProgressScreen.vue';

const storageKey = 'screen-size';

export default {
    name: 'ScreenController',
    data() {
        return {
            darkBackground: false,
            mainContainerWidth: 0,
            mainContainerHeight: 0,
        };
    },
    computed: {
        ...mapState('ScreensStore', ['currentScreen']),
        ...mapGetters('GameStore', ['getDifficulty', 'isGameStarted', 'isGameFinished']),
        ...mapGetters('ScreensStore', ['getNextGamesCount']),
        ...mapGetters('loader', ['isShowLoader']),
        screenComponent() {
            if (this.currentScreen) {
                switch (this.currentScreen.type) {
                    case 'comics':
                        return ComicsScreen;
                    case 'dialog':
                        return DialogScreen;
                    case 'game':
                        return MinigameScreen;
                    case 'progress':
                        return ProgressScreen;
                    default:
                        return null;
                }
            }
            return null;
        },
        mainContainerStyle() {
            return {
                fontSize:
                    this.$mq == 'sm'
                        ? (16 / 640) * this.mainContainerWidth + 'px'
                        : (16 / 1820) * this.mainContainerWidth + 'px',
                width: `${this.mainContainerWidth}px`,
                height: this.currentScreen.type === 'comics' ? '100%' : `${this.mainContainerHeight}px`,
                maxWidth: `${this.mainContainerWidth}px`,
                maxHeight: this.currentScreen.type === 'comics' ? '100%' : `${this.mainContainerHeight}px`,
            };
        },
    },

    created() {
        const screenOption = localStorage.getItem(storageKey);
        if (screenOption) {
            const { width, height } = JSON.parse(screenOption);
            this.mainContainerWidth = width;
            this.mainContainerHeight = height;
        }
    },
    mounted() {
        this.ro = new ResizeObserver(this.onClientResize);
        this.ro.observe(this.$refs.screenContainer);
    },

    beforeDestroy() {
        this.ro.unobserve(this.$refs.screenContainer);
    },

    methods: {
        ...mapActions('ScreensStore', ['nextScreen', 'previousScreen', 'setCurrentScreen', 'loadScript']),
        ...mapActions('GameStore', ['setDifficulty', 'setGameFinished']),
        darkenBackground(val) {
            this.darkBackground = val;
        },
        onClientResize(event) {
            const { offsetWidth, offsetHeight } = event[0].target;
            const aspect_ratio = 9 / 16;
            if (offsetHeight / offsetWidth > aspect_ratio) {
                this.mainContainerWidth = offsetWidth;
                this.mainContainerHeight = offsetWidth * aspect_ratio;
            } else {
                this.mainContainerWidth = offsetHeight / aspect_ratio;
                this.mainContainerHeight = offsetHeight;
            }

            localStorage.setItem(
                storageKey,
                JSON.stringify({ width: this.mainContainerWidth, height: this.mainContainerHeight })
            );
        },
    },
    watch: {
        isGameStarted: {
            handler(bool) {
                if (bool) {
                    this.loadScript(`${this.getDifficulty}.json`, { root: true });
                }
            },
            immediate: true,
        },
        getNextGamesCount: {
            handler(val) {
                if (val == 0 && !this.isGameFinished) {
                    this.setGameFinished();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.screen-controller {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s;
    padding: 0.5625em 1em 12px 1em;
    overflow-y: hidden;
    overflow-x: hidden;

    .screen-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &--darken {
        background-color: rgba(35, 111, 189, 0.6);
    }
    &--overflow-y {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.screen-enter-active {
    transition: opacity 0.3s;
    transition-delay: 0.3s;
}

.screen-leave-active {
    transition: opacity 0.3s;
}

.screen-leave-to,
.screen-enter {
    opacity: 0;
    position: absolute !important;
}
</style>
