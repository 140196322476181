import { DoubleLinkedList } from 'double-linkedlist';

export const ScreensStore = {
    namespaced: true,
    state: {
        script: null,
        currentScreen: null,
    },
    mutations: {
        SET_SCRIPT(state, payload) {
            state.script = payload;
        },

        INIT_FIRST_SCREEN(state) {
            if (state.script.isEmpty()) throw new Error('Load script at first');
            state.currentScreen = state.script.getHead();
        },

        SET_CURRENT_SCREEN_NODE(state, payload) {
            if (payload && payload.node) state.currentScreen = payload.node;
        },

        SET_CURRENT_SCREEN(state, payload) {
            if (payload && payload.screenId) {
                const screenNode = state.script.findAll({ id: payload.screenId })[0];
                if (screenNode) state.currentScreen = screenNode;
            }
        },
    },
    actions: {
        loadScript: {
            root: true,
            handler({ commit }, path) {
                const rawSccript = require(`@/configs/scripts/${path}`);
                const linkedlist = new DoubleLinkedList();
                rawSccript.map(el => linkedlist.insertAtEnd(el));
                commit('SET_SCRIPT', linkedlist);
                commit('INIT_FIRST_SCREEN');
            },
        },

        nextScreen({ commit, state, rootGetters }) {
            const nextScreen = state.currentScreen.getNext();
            if (nextScreen) commit('SET_CURRENT_SCREEN_NODE', { node: nextScreen });
            else {
                const link = document.createElement('a');
                link.href = rootGetters['GameStore/getCertificateLink'];
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },

        previousScreen({ commit, state }) {
            const previousScreen = state.currentScreen.getPrevious();
            if (previousScreen) commit('SET_CURRENT_SCREEN_NODE', { node: previousScreen });
        },

        setCurrentScreen({ commit }, screenId) {
            commit('SET_CURRENT_SCREEN', { screenId });
        },
    },

    getters: {
        getScript: state => state.script,
        getCurrentScreen: state => state.currentScreen,
        getNextGamesCount: state => {
            const script = state.script.toArray();
            return script
                .slice(script.findIndex(screen => screen.id == state.currentScreen.id))
                .filter(screen => screen.type == 'game').length;
        },
    },
};
