<template>
    <div class="stage-card-container">
        <transition name="fade">
            <div
                :class="[
                    'finish-marker',
                    {
                        'even-stage': cardIndex % 2 === 0,
                        'odd-stage': cardIndex % 2 !== 0,
                    },
                    status,
                ]"
                v-if="showMarker"
            >
                <progress-marker />
            </div>
        </transition>
        <div :class="['color-border-outer', status]">
            <div :class="['color-border-inner', status]">
                <div class="card" :class="status">
                    <div class="card-index">
                        <div class="outer-border">
                            <div class="inner-border">{{ cardIndex }}</div>
                        </div>
                    </div>
                    <div class="icon-container">
                        <transition name="fade" mode="out-in">
                            <component :is="computedIcon" v-if="status !== 'completed'" />
                            <img :src="getCharacterImage" v-else />
                        </transition>
                    </div>
                    <div class="card-title">
                        <div class="text">
                            {{ title }}
                        </div>
                    </div>
                    <div class="role-container">
                        <transition name="fade" mode="out-in">
                            <div v-if="status === 'completed'" :key="1">
                                <div class="title">Открыта роль</div>
                                <div class="role">{{ role }}</div>
                            </div>
                            <div v-else class="role-icon" :key="2">
                                <locked-marker-small />
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @category ProgressScreen
 * @module StageCard
 * @desc Компонент с карточкой этапа, отображаемой на экране прогресса
 *
 * @vue-prop {string} status Текущий статус карточки
 * @vue-prop {string} title Название этапа
 * @vue-prop {string} stageImage id изображения персонажа
 * @vue-prop {number} cardIndex Порядковый номер карточки
 * @vue-prop {string} role Роль, отображаемую на карточке
 * @vue-prop {boolean} showMarker Параметр видимости маркера этапа
 *
 * @vue-computed {string} getDifficulty Возвращает сложность игры (для 1-4, 5-9, 10-11 классов) из Vuex
 * @vue-computed getImage Возвращает изображение загруженное в Vuex
 * @vue-computed {Base64String} getCharacterImage Возвращает изображение персонажа этапа, отображаемого на карточке
 * @vue-computed {iconComponent} computedIcon Возвращает компонент с необходимой иконкой
 */
import progressMarker from '@/components/Icons/progressMarker.vue';
import completeMarker from '@/components/Icons/completeMarker.vue';
import lockedMarkerBig from '@/components/Icons/lockedMarkerBig.vue';
import lockedMarkerSmall from '@/components/Icons/lockedMarkerSmall.vue';
import unlockedMarker from '@/components/Icons/unlockedMarker.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'StageCard',
    props: {
        status: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        stageImage: {
            type: String,
            default: '',
        },
        cardIndex: {
            type: Number,
            required: true,
        },
        role: {
            type: String,
            required: true,
        },
        showMarker: {
            type: Boolean,
            default: false,
        },
    },
    components: { progressMarker, completeMarker, lockedMarkerBig, lockedMarkerSmall, unlockedMarker },
    computed: {
        ...mapGetters('assets', ['getImage']),
        ...mapGetters('GameStore', ['getDifficulty']),
        getCharacterImage() {
            return this.getImage(`${require(`@/assets/progress/${this.getDifficulty}/stage${this.cardIndex}.svg`)}`);
        },
        computedIcon() {
            switch (this.status) {
                case 'unlocked':
                    return unlockedMarker;
                case 'not-started':
                    return lockedMarkerBig;
                default:
                    return null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.stage-card-container {
    position: relative;
    z-index: 3;
    width: fit-content;
    .color-border-outer {
        transition: border-color 1s;
        border-radius: 3em;
        padding: 0.4375em;
        border: 0.0625em solid transparent;
        &.completed {
            border-color: rgb(160, 255, 232);
        }
    }
    .color-border-inner {
        transition: border-color 1s;
        border-radius: 2.5em;
        padding: 0.4375em;
        border: 0.0625em solid transparent;
        &.completed {
            border-color: rgb(52, 220, 139);
        }
    }
    @media (max-width: 900px) {
        .color-border-outer {
            border-radius: 1em;
            padding: 0.14971em;
            border-width: 0.0213em;
        }
        .color-border-inner {
            border-radius: 0.8125em;
            padding: 0.1497em;
            border-width: 0.0213em;
        }
    }
}
.finish-marker {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5em;
    &.odd-stage {
        top: -1.65em;
    }
    &.even-stage {
        bottom: -1.5em;
        margin-bottom: 0;
        transform: rotate(180deg);
    }
    &.unlocked {
        svg :deep path {
            fill: rgb(58, 156, 254);
        }
    }
    &.completed {
        svg :deep path {
            fill: rgb(0, 194, 124);
        }
    }
    display: flex;
    justify-content: center;
    z-index: 1;
    svg {
        width: 6em;
        height: 5.625em;
    }
    @media (max-width: 900px) {
        &.odd-stage {
            top: -0.55em;
        }
        &.even-stage {
            bottom: -0.5em;
        }
        svg {
            width: 2em;
            height: 1.875em;
        }
    }
}
.card {
    position: relative;
    width: 18em;
    height: 21em;
    padding: 1em 0.75em 2em;
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-clip: padding-box; /* !importanté */
    border: solid 0.5em transparent; /* !importanté */
    transition: background-color 1s, border-color 1s, box-shadow 1s;
    .card-index {
        position: absolute;
        top: 0.75em;
        left: 0.75em;
        .outer-border {
            border: 0.0625em solid white;
            border-radius: 50%;
            transition: border-color 1s;
        }
        .inner-border {
            border: 0.125em solid white;
            border-radius: 50%;
            margin: 0.125em;
            width: 1.5em;
            height: 1.5em;
            text-align: center;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 800;
            font-size: 1.5em;
            line-height: 120%;
            color: #ffffff;
            text-shadow: 0px 0.0625em 0px rgba(0, 0, 0, 0.3);
            transition: border-color 1s;
        }
    }
    .icon-container {
        width: 7.5em;
        height: 7.5em;
        border-radius: 50%;
        background-color: rgba(18, 184, 104, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 1s;
        position: relative;
        svg {
            width: 3em;
            height: 3em;
            object-fit: contain;
        }
        img {
            position: absolute;
            width: 9.125em;
            height: 8.5em;
            bottom: 0;
        }
    }
    .card-title {
        margin-bottom: 0;
        .text {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 800;
            font-size: 2em;
            line-height: 100%;
            text-align: center;
            color: #ffffff;
            text-shadow: 0px 0.125em 0px rgba(0, 0, 0, 0.3);
        }
    }
    .role-container {
        width: 15em;
        height: 4em;
        border-radius: 1em;
        background: white;
        box-shadow: 0px 0.24em 0px white;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: background-color 1s, box-shadow 1s;
        .title {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 1em;
            line-height: 1em;
            text-align: center;
            color: #262c2e;
            padding-top: 0.75em;
        }
        .role {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 1.25em;
            line-height: 1.5em;
            text-align: center;
            color: white;
        }
        .role-icon {
            height: 100%;
            display: flex;
            align-items: center;
            svg {
                width: 1.5em;
                height: 1.875em;
                object-fit: contain;
            }
        }
    }
    @media (max-width: 900px) {
        width: 6.125em;
        height: 7.125em;
        padding: 0.3125em 0.625em 0.3125em;
        border-radius: 0.6875em;

        border: solid 0.1875em transparent; /* !importanté */

        .card-index {
            top: 0.3125em;
            left: 0.3125em;

            .inner-border {
                border: 0.086em solid white;
                margin: 0.086em;
                width: 1.352em;
                height: 1.352em;
                font-weight: 800;
                font-size: 0.5em;
                line-height: 120%;
                color: #ffffff;
                text-shadow: 0px 0.0625em 0px rgba(0, 0, 0, 0.3);
                transition: border-color 1s;
            }
        }
        .icon-container {
            width: 2.563em;
            height: 2.563em;
            svg {
                width: 1em;
                height: 1em;
            }
            img {
                width: 3.125em;
                height: 2.914em;
            }
        }
        .card-title {
            .text {
                font-size: 0.6875em;
            }
        }
        .role-container {
            width: 5.125em;
            height: 1.5em;
            border-radius: 0.5em;
            box-shadow: 0px 0.086em 0px white;
            .title {
                font-size: 0.375em;
                line-height: 103%;
                padding-top: 0.667em;
            }
            .role {
                font-size: 0.5em;
                line-height: 103%;
            }
            .role-icon {
                svg {
                    width: 0.5em;
                    height: 0.625em;
                }
            }
        }
    }
    &.unlocked {
        background-color: #65b2ff;

        border-color: #3a9cfe;
        box-shadow: inset 0px -2.563em 0.625em -1.313em rgba(255, 255, 255, 0.3),
            inset 0px 2.563em 0.625em -1.313em rgba(255, 255, 255, 0.25);

        .icon-container {
            background-color: #4194ea;
        }
        .card-index {
            .outer-border {
                border-color: #9accff;
                .inner-border {
                    border-color: #4194ea;
                }
            }
        }
        .role-container {
            box-shadow: 0px 0.25em 0px #378ef4;
            background-color: #9accff;
            @media (max-width: 900px) {
                box-shadow: 0px 0.086em 0px #378ef4;
            }
        }
    }
    &.completed {
        background-color: #2ee38c;

        border-color: #00c27c;

        box-shadow: inset 0px 10.5em 8.5em #5efaae, inset 0px -3.125em 2em #1df28c,
            inset 0px -5em 2em rgba(0, 0, 0, 0.05);

        .icon-container {
            background-color: #2ee38c;
        }
        .card-index {
            .outer-border {
                border-color: #a0ffe8;
                .inner-border {
                    border-color: #12b868;
                }
            }
        }
        .role-container {
            box-shadow: 0px 0.25em 0px #1d9f60;
            .role {
                color: #00934c;
            }
            @media (max-width: 900px) {
                box-shadow: 0px 0.086em 0px #1d9f60;
            }
        }
    }
    &.not-started {
        background-color: #cce4eb;
        box-shadow: inset 0px -2.563em 0.625em -1.313em rgba(255, 255, 255, 0.3),
            inset 0px 2.563em 0.625em -1.313em rgba(255, 255, 255, 0.25);

        border-color: #92b7c2;

        .icon-container {
            background-color: rgb(158, 188, 198);
        }
        .card-index {
            .outer-border {
                border-color: #def0f5;
                .inner-border {
                    border-color: #9ebcc6;
                }
            }
        }
        .role-container {
            background: #def0f5;
            box-shadow: 0px 0.25em 0px #b0c8cf;
            @media (max-width: 900px) {
                box-shadow: 0px 0.086em 0px #b0c8cf;
            }
        }
    }
}
</style>
