import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMq from 'vue-mq';
import { BootstrapVue, CollapsePlugin, ButtonPlugin, ButtonGroupPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import VueObserveVisibility from 'vue-observe-visibility';
import Particles from 'vue2-particles';
import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(Particles);

Vue.config.productionTip = false;

// Сделайте BootstrapVue доступным для всего проекта
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// При желании установите плагин компонентов иконок BootstrapVue
Vue.use(CollapsePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(VueObserveVisibility);

Vue.use(VueMq, {
    breakpoints: {
        sm: 901,
        lg: Infinity,
    },
    defaultBreakpoint: 'sm',
});

if (process.env.VUE_APP_ENABLE_YM) {
    Vue.use(VueYandexMetrika, {
        id: 51312949,
        router: router,
        env: process.env.NODE_ENV,
    });
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
