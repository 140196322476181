var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-screen-container"},[_c('div',{class:[
            'stages-container',
            {
                'even-stages': _vm.stages.length % 2 === 0,
                'odd-stages': _vm.stages.length % 2 !== 0,
            },
        ]},[_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-line",style:(_vm.computeProgressWidth)})]),_vm._l((_vm.stages),function(item,index){return [_c('stage-card',{key:index,attrs:{"status":_vm.computeStatus(index + 1),"title":item.name,"role":item.role,"stageImage":item.stagePic,"cardIndex":index + 1,"showMarker":(index === _vm.currentStage - 1 && _vm.animationStep < 4) ||
                    (index === _vm.currentStage && _vm.animationStep === 4)}}),_c('div',{key:item.index,staticClass:"divider-container"},[_c('div',{staticClass:"divider"})])]})],2),_c('div',{staticClass:"character-container"},[_c('character-card',{attrs:{"characterPhrase":_vm.screenConfig.characterPhrase,"characterName":_vm.screenConfig.characterName}},[_c('button',{staticClass:"button--primary",on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v("Далее")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }